import styled from "styled-components";

const DefaultPageTitle = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-bottom: 30px;
  padding-bottom:1em;
  border-bottom: solid 1.2px rgba(0, 0, 0, 0.1);
  h2 { font-size: var(--font-medium);
  color: var(--default-title-color);
  font-weight: 400;
  }
  button {
    background: var(--primary-color);
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 15px 20px;
    font-size: var(--font-small);
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);
    display: flex;
    align-items: center;
    gap: 5px;
  
    &.danger {
      background: var(--danger-color);
    }
  
    &.success {
      background: var(--success-color);
    }
  
    &.info {
      background: var(--info-color);
    }
  
    &.white {
      background: white;
      color: #444;
      border: solid 1px var(--default-dark-gray);
    }
  
    &.warning {
      background: #ffc107;
      color: black;
    }
  
    &.small {
      padding: 3px 6px;
    }
  }
`;

export { DefaultPageTitle };
