import styled from 'styled-components';

const RegisterKeyContainer = styled.div`
  .amount {
    margin-top: 15px;
  }
  button {
    margin-top: 15px;
  }
`;

export { RegisterKeyContainer };
