import React, { useEffect, useState } from "react";
import ReactDatePicker from 'react-datepicker';
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import { hideModal, showModal } from "../../helpers/modal";
import CutImage from "../../components/CutImage";
import checkEmptyString from "../../helpers/check-empty-string";
import {
  getLive as getLiveService,
  createLive as createLiveService,
  updateLive as updateLiveService
} from '../../services/lives';
import {
  ContentThumbnail,
  CreateAndEditContentContainer,
  ThumbnailUploadContainer,
} from "./style";
import LiveForCreate from "../../models/for-create/lives";
import { uploadFile } from "../../services/files";
import Live from "../../models/lives";
import LiveForUpdate from "../../models/for-update/lives";

interface CreateAndEditLiveProps {
  liveId: string;
}

const CreateAndEditLive: React.FC = () => {
  const { liveId } = useParams<CreateAndEditLiveProps>();
  const isEditting = !!liveId;
  const history = useHistory();

  const fiveMinutesDivisorFutureDate = (date: Date) => {
    const actualDate = date;
    const actualMinutes = actualDate.getMinutes() + 5;
    const actualHours = actualDate.getHours();

    let liveInitialMinutes = actualMinutes;
    let hourIncrement = 0;
    let dayIncrement = 0;
    while ((liveInitialMinutes || 5) % 5 !== 0) {
      if (liveInitialMinutes === 59) {
        liveInitialMinutes = 0;
        hourIncrement++;
        if (actualHours === 23) {
          dayIncrement++;
        }
      } else {
        liveInitialMinutes++;
      }
    }

    actualDate.setDate(actualDate.getDate() + dayIncrement);
    actualDate.setHours(actualDate.getHours() + hourIncrement);
    actualDate.setMinutes(liveInitialMinutes);

    return actualDate;
  };

  const [name, setName] = useState('');
  const [live, setLive] = useState({} as Live);
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState<any>(fiveMinutesDivisorFutureDate(new Date()));
  const [finishDate, setFinishDate] = useState<any>();
  const [, setThumbnailToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState('');

  const validateLive = (isCreate = true) => {
    if (checkEmptyString(name)) {
      throw new Error('Informe um nome válido para a live!');
    }

    if (checkEmptyString(description)) {
      throw new Error('Informe uma descrição valida para a live!');
    }

    if (isCreate) {
      if (new Date().getTime() >= startDate.getTime()) {
        throw new Error(
          'Informe uma data de inicio valida para a live! Certifique-se que a data seja ao menos 5 minutos superior a data atual!',
        );
      }

      if (finishDate && new Date().getTime() >= finishDate.getTime()) {
        throw new Error(
          'Informe uma data final valida para a live! Certifique-se que a data seja ao menos 5 minutos superior a data de inicio da live!',
        );
      }

      if (finishDate && startDate.getTime() >= finishDate.getTime()) {
        throw new Error('Informe uma data de inicio inferior a data de fim!');
      }
    }
  };

  function dataURLtoFile(dataurl: string, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)?.[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const createLive = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validateLive();

      const newLive: LiveForCreate = {
        name,
        description,
        start: startDate.toISOString(),
        stop: finishDate.toISOString(),
        vod: 2,
      };

      if (thumbnail && thumbnail.length && thumbnail.includes('base64')) {
        try {
          const image = dataURLtoFile(thumbnail, `thumbnail-${name}.png`);

          const formData = new FormData();
          formData.append('file', image);

          const { reference } = await uploadFile(formData);
          newLive.thumbnail_url = reference;
        } catch (e) {
          throw new Error('Erro ao incluir a imagem de capa. ' + e.message);
        }
      }

      await createLiveService(newLive);

      Swal.fire({
        title: "Sucesso!",
        text: "Live criado com sucesso!",
        icon: "success",
      });

      goToLives();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar a Live. " + error.message,
        icon: "error",
      });
    }
  };

  const updateLive = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validateLive(false);

      const newLive: LiveForUpdate = {
        name,
        description,
      };

      if (thumbnail && thumbnail.length && thumbnail.includes('base64')) {
        try {
          const image = dataURLtoFile(thumbnail, `thumbnail-${name}.png`);

          const formData = new FormData();
          formData.append('file', image);

          const { reference } = await uploadFile(formData);
          newLive.thumbnail_url = reference;
        } catch (e) {
          throw new Error('Erro ao alterar a imagem de capa. ' + e.message);
        }
      }

      await updateLiveService(liveId, newLive);

      Swal.fire({
        title: "Sucesso!",
        text: "Live editado com sucesso!",
        icon: "success",
      });

      goToLives();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar a Live. " + error.message,
        icon: "error",
      });
    }
  };

  const selectThumbnail = () => {
    showModal(
      "Selecionar Imagem de Capa",
      <CutImage aspect={1.812} onCutImage={onCutImage} />
    );
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const goToLives = () => {
    history.push("/lives");
  };

  const getLive = async () => {
    const localLive = await getLiveService(liveId);
    setLive(localLive);
    setName(localLive.name);
    setDescription(localLive.description);
    setStartDate(
      localLive.start
        ? new Date(localLive.start)
        : fiveMinutesDivisorFutureDate(new Date()),
    );
    setFinishDate(localLive.stop ? new Date(localLive.stop) : '');
    setThumbnail(localLive.thumbnail_url);
  };

  useEffect(() => {
    if (liveId) {
      getLive();
    }
  }, [liveId]);

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/lives">Lives</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Live</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Live
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">Nome</label>
          <DefaultInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="name"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description || ''}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="description">Início</label>
          <ReactDatePicker
            id="startDate"
            selected={startDate}
            showTimeSelect
            dateFormat="dd/MM/yyyy hh:mm"
            timeIntervals={5}
            onChange={date => setStartDate(date)}
            readOnly={isEditting}
            onChangeRaw={event => event.preventDefault()}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="description">Fim</label>
          <ReactDatePicker
            id="finishDate"
            selected={finishDate}
            showTimeSelect
            dateFormat="dd/MM/yyyy hh:mm"
            timeIntervals={5}
            onChange={date => setFinishDate(date)}
            readOnly={isEditting}
            onChangeRaw={event => event.preventDefault()}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference">Capa</label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToLives}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateLive(e) : createLive(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditLive;
