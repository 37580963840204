import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { generateFinishedCoursesReport } from '../../services/reports';
import { FinishedCoursesContainer } from './style';

const FinishedCourses: React.FC = () => {
  const [dateInitStart, setDateInitStart] = useState('');
  const [dateInitFinish, setDateInitFinish] = useState('');

  const [dateFinishStart, setDateFinishStart] = useState('');
  const [dateFinishFinish, setDateFinishFinish] = useState('');

  const generateReport = async (reportType: string) => {
    const queryStrings: any = {
      start_at_gte: dateInitStart,
      start_at_lte: dateInitFinish,
      finish_at_lte: dateFinishFinish,
      finish_at_gte: dateFinishStart,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateFinishedCoursesReport(reportType, queryStrings);
  };

  return (
    <FinishedCoursesContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/home">Home</Link>,
          <span>Relatórios</span>,
          <span>Cursos Finalizados</span>,
        ]}
      />

      <DefaultPageTitle>Relatório de Cursos Finalizados</DefaultPageTitle>

      <DefaultFilterForm>
        <span>
          <label htmlFor="startAt">Data de início do Curso - De</label>
          <input
            type="date"
            id="startAtGte"
            max={dateInitStart}
            onChange={e => setDateInitStart(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className="label">até</span>
          <input
            type="date"
            id="startAtLte"
            min={dateInitFinish}
            onChange={e => setDateInitFinish(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <label htmlFor="startAt">Data de finalização do Curso - De</label>
          <input
            type="date"
            id="startAtGte"
            max={dateFinishStart}
            onChange={e => setDateFinishStart(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className="label">até</span>
          <input
            type="date"
            id="startAtLte"
            min={dateFinishFinish}
            onChange={e => setDateFinishFinish(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span className="entire-row center">
          <DefaultButton onClick={() => generateReport('pdf')} type="button">
            Gerar Relatório (PDF)
          </DefaultButton>{' '}
          <DefaultButton onClick={() => generateReport('csv')} type="button">
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </FinishedCoursesContainer>
  );
};

export default FinishedCourses;
