import React, { useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import sanitizeHtml from 'sanitize-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const textareaStyle = {
	border: '1px solid #ced4da',
	borderRadius: '0.25rem',
	flex: '1 1 80%',
  height: '560px',
  overflow: 'hidden'
}

const toolbarOptions = {
	options: [
		'inline',
		'blockType',
		'fontSize',
		'list',
		'textAlign',
		'colorPicker',
		'link',
		'emoji',
		'image',
		'remove',
		'history',
	],
}

const editorStyleObject = {
  margin: '0 1rem',
  maxHeight: '480px'
}

type TDescriptionTextarea = {
  description: string,
  setEditorState: Function,
  editorState: EditorState,
}

const getCleanHtml = (editorState: any) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent());
	const descriptionHtml = draftToHtml(rawContentState);

	return sanitizeHtml(descriptionHtml, {
		allowedAttributes: {
			...sanitizeHtml.defaults.allowedAttributes,
			span: ['style'],
			p: ['style'],
		},
	});
}

const DescriptionTextarea: React.FC<TDescriptionTextarea> = ({ description, setEditorState, editorState }) => {
  const onEditorStateChange = (editorState: any) => {
		setEditorState(editorState);
	};

  useEffect(() => {
		const contentBlock = htmlToDraft(description);
    
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorStateCreated = EditorState.createWithContent(contentState);
			
      setEditorState(editorStateCreated);
		}
	}, [description]);

  return (
    <div className="editor-terms">
      <div style={textareaStyle}>
        <Editor
          toolbar={toolbarOptions}
          editorStyle={editorStyleObject}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
    </div>
  );
};

export { DescriptionTextarea, getCleanHtml };
