import httpClient from "../http-client";
import { uploadFile } from './files';

const getCategory = async (categoryId: string) => {
  const category = (await httpClient.get<any>(`categories/${categoryId}`)).data;

  return category;
};

const getAllCategories = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const categories = (await httpClient.get<any[]>("categories", { params: filters })).data;

  return categories;
};

const createCategory = async (newCategory: any) => {
  const createCategoryResponse = (
    await httpClient.post<any>(`categories`, newCategory)
  ).data;

  return createCategoryResponse;
};

const updateCategory = async (
  categoryId: string,
  newContentForCategory: any
) => {
  await httpClient.put(`categories/${categoryId}`, newContentForCategory);
};

const deleteCategory = async (categoryId: string) => {
  await httpClient.delete(`categories/${categoryId}`);
};

const changeCategoryThumbnail = async (
  categoryId: string,
  formData: FormData
) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`categories/${categoryId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const changeCategoryLogo = async (categoryId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`categories/${categoryId}/logo`, {
    logo: uploadedFile.reference,
  });
};

const activateOrInactivateCategory = async (
  categoryId: any,
  activate: boolean
) => {
  await httpClient.put(`categories/${categoryId}/active`, {
    is_active: activate,
  });
};

export {
  getAllCategories,
  deleteCategory,
  updateCategory,
  createCategory,
  getCategory,
  activateOrInactivateCategory,
  changeCategoryThumbnail,
  changeCategoryLogo
};
