import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Exam from '../../models/exam';
import { ExamsContainer } from './style';
import {
  getExams as getExamsService,
  deleteExam as removeExamService,
  activateOrInactivateExam as activateOrInactivateExamService,
  getReferenceExam,
} from '../../services/exams';
import { BiEdit, BiTrash } from 'react-icons/bi';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from 'react-icons/ai';
import Swal from 'sweetalert2';
import DefaultInput from '../../components/DefaultInput';
import { DefaultCreateButtonAndSearchFormContainer } from '../../components/DefaultCreateButtonAndSearchFormContainer';

const Exams: React.FC = () => {
  const history = useHistory();
  const [exams, setExams] = useState([] as Exam[]);
  const [examTitle, setExamTitle] = useState('');

  const createExam = () => {
    history.push('/exams-questions/create-exam');
  };

  const editExam = useCallback(
    (examId: string) => {
      history.push(`/exams-questions/edit-exam/${examId}`);
    },
    [history],
  );

  const removeExam = useCallback(async (examId: string) => {
    const referenceExist = await getReferenceExam(examId);

    if (referenceExist) {
      Swal.fire({
        title: 'Erro',
        text: 'A prova está vinculada a um curso.',
        icon: 'error',
      });
    } else {
      Swal.fire({
        title: 'Confirmação',
        text: 'Tem certeza que deseja remover esta prova?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        icon: 'question',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await removeExamService(examId);
            Swal.fire({
              title: 'Sucesso!',
              text: 'Prova deletada com sucesso!',
              icon: 'success',
            });
            await refreshExams();
          } catch (error) {
            Swal.fire({
              title: 'Erro',
              text: 'Erro ao remover prova. ' + error.message,
              icon: 'error',
            });
          }
        }
      });
    }
  }, []);

  const activateOrInactivateExam = useCallback(
    async (examId: string, activate: boolean) => {
      const referenceExist = await getReferenceExam(examId);

      if (referenceExist) {
        Swal.fire({
          title: 'Erro',
          text: 'A prova está vinculada a um curso.',
          icon: 'error',
        });
      } else {
        Swal.fire({
          title: 'Confirmação',
          text: `Tem certeza que deseja ${
            activate ? 'ativar' : 'inativar'
          } esta prova?`,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          icon: 'question',
        }).then(async result => {
          if (result.isConfirmed) {
            try {
              await activateOrInactivateExamService(examId);
              Swal.fire({
                title: 'Sucesso!',
                text: `Prova ${
                  activate ? 'ativada' : 'desativada'
                } com sucesso!`,
                icon: 'success',
              });
              await refreshExams();
            } catch (error) {
              Swal.fire({
                title: 'Erro',
                text: `Erro ao ${activate ? 'ativar' : 'inativar'} prova. ${
                  error.message
                }`,
                icon: 'error',
              });
            }
          }
        });
      }
    },
    [],
  );

  const refreshExams = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const localExams = await getExamsService({ title: examTitle });
    setExams(localExams);
  };

  useEffect(() => {
    refreshExams();
  }, []);

  const examsToShow = useMemo(() => {
    return exams && exams.length
      ? exams.map(exam => ({
          ...exam,
          enabled: exam.enabled ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() => editExam(exam.id)}
                className="small info"
                title="Editar Prova"
              >
                <BiEdit />
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => activateOrInactivateExam(exam.id, !exam.enabled)}
                className="small warning"
                title={(exam.enabled ? 'Habilitar' : 'Desabilitar') + ' Prova'}
              >
                {exam.enabled ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => removeExam(exam.id)}
                className="small danger"
                title="Excluir Prova"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [exams, editExam, activateOrInactivateExam, removeExam]);

  useEffect(() => {
    if (!examTitle) {
      refreshExams();
    }
  }, [examTitle]);

  return (
    <ExamsContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Provas e Questões</span>,
          <span>Provas</span>,
        ]}
      />

      <DefaultPageTitle>Provas</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createExam}>Criar Prova</DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={refreshExams}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '10px',
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Digite sua pesquisa aqui..."
            value={examTitle}
            onChange={e => setExamTitle(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton
            className="small"
            style={{ margin: 0, height: '43px' }}
            type="submit"
          >
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Qtde. de Questões</span>,
            propName: 'amount',
          },
          {
            headerLabel: <span>Habilitado</span>,
            propName: 'enabled',
            order: (_: any[], currentOrder: string) => {
              setExams([
                ...exams.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes('enabled') &&
                    currentOrder.includes('asc');
                  if (isDescendant) {
                    return a.enabled ? 1 : -1;
                  } else {
                    return a.enabled ? -1 : 1;
                  }
                }),
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={examsToShow}
        emptyListMessage="Não foram encontradas provas cadastradas!"
      />
    </ExamsContainer>
  );
};

export default Exams;
