import React from "react";
import Article from "../../../../models/article";
import {
  ArticlePreviewContainer,
  ArticlPreviewContentContainer,
  ArticlPreviewThumbnail,
  ArticlPreviewThumbnailContainer,
} from "./style";
import mdParser from "marked";

interface ArticlePreviewProps {
  article: Article;
}

const ArticlePreview: React.FC<ArticlePreviewProps> = ({ article }) => {
  return (
    <ArticlePreviewContainer>
      <ArticlPreviewThumbnailContainer>
        <ArticlPreviewThumbnail src={article.thumbnail} />
      </ArticlPreviewThumbnailContainer>

      <ArticlPreviewContentContainer
        className="mdarea"
        dangerouslySetInnerHTML={{
          __html: mdParser(article.content || ""),
        }}
      ></ArticlPreviewContentContainer>
    </ArticlePreviewContainer>
  );
};

export default ArticlePreview;
