import styled from 'styled-components';

const BannerContainer = styled.div``;

const AddItemButton = styled.div`
  border-bottom: 1px solid #eee;
  padding: 16px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const TrashButton = styled.button`
  width: 40px;
  height: 40px;
  background: var(--danger-color);
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);
  margin: 8px auto 0;
`;

export { BannerContainer, AddItemButton, TrashButton };
