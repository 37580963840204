import styled from 'styled-components';

const CreateAndEditCategoryContainer = styled.div`
  .extra-materials {
    background: var(--primary-color);
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 15px 20px;
    font-size: var(--font-small);
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08),
      0px 4px 6px rgba(50, 50, 93, 0.1);
  }
`;

const ContentUploadOrSelectContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;
  iframe {
    margin-top: 10px;
    width: 600px;
    height: 340px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ThumbnailUploadContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;
  img {
    margin-top: 10px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ContentThumbnail = styled.img`
  max-width: 600px;
  background-color: #ebebeb;
`;

const CategoryColor = styled.div`
  ${(props) => (props.color ? `background-color: ${props.color};` : '')}
  width: 100px;
  height: 40px;
  margin: 10px;
`;

export {
  CreateAndEditCategoryContainer,
  ContentUploadOrSelectContainer,
  ThumbnailUploadContainer,
  ContentThumbnail,
  CategoryColor,
};