import httpClient from '../http-client';

const getTagCategory = async (tagCategoriesId: string) => {
  const category = (await httpClient.get<any>(`tag-categories/${tagCategoriesId}`)).data;
  return category;
};

const getAllTagCategories = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const categories = (
    await httpClient.get<any[]>('tag-categories', { params: filters })
  ).data;

  return categories;
};

const createTagCategory = async (newTagCategory: any) => {
  const createCategoryResponse = (
    await httpClient.post<any>(`tag-categories`, newTagCategory)
  ).data;

  return createCategoryResponse;
};

const updateTagCategory = async (
  tagCategoryId: string,
  newContentForTagCategory: any,
) => {
  await httpClient.put(`tag-categories/${tagCategoryId}`, newContentForTagCategory);
};

const deleteTagCategory = async (tagCategoryId: string) => {
  await httpClient.delete(`tag-categories/${tagCategoryId}`);
};

const activateOrInactivateTagCategory = async (
  tagCategoryId: any,
  activate: boolean,
) => {
  await httpClient.put(`tag-categories/${tagCategoryId}/active`, {
    is_active: activate,
  });
};

export {
  getAllTagCategories,
  deleteTagCategory,
  updateTagCategory,
  createTagCategory,
  getTagCategory,
  activateOrInactivateTagCategory,
};
