import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { EngagementContainer } from './style';
import Select from 'react-select';
import DefaultButton from '../../components/DefaultButton';
import { generateEngagementReport } from '../../services/reports';
import { getAllCourses } from '../../services/trails';
import { getUsersForSelect } from '../../services/users';
import Trail from '../../models/trail';

const Engagement: React.FC = () => {
  const [courses, setCourses] = useState(
    [] as { value: string; label: string }[],
  );
  const [users, setUsers] = useState([] as { value: string; label: string }[]);
  const [selectedCourses, setSelectedCourses] = useState([] as string[]);
  const [selectedUsers, setSelectedUsers] = useState([] as string[]);

  const [startCourseStart, setStartCourseStart] = useState('');
  const [startCourseFinish, setStartCourseFinish] = useState('');

  const [finishCourseStart, setFinishCourseStart] = useState('');
  const [finishCourseFinish, setFinishCourseFinish] = useState('');

  const generateReport = async (reportType: string) => {
    const queryStrings: any = {
      user_id_ft: selectedUsers,
      trail_id_ft: selectedCourses,
      start_at_gte: startCourseStart,
      start_at_lte: startCourseFinish,
      finish_at_lte: finishCourseFinish,
      finish_at_gte: finishCourseStart,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateEngagementReport(reportType, queryStrings);
  };

  useEffect(() => {
    (async () => {
      const localCourses = await getAllCourses();
      const localUsers = await getUsersForSelect();

      if (localCourses && localCourses.length) {
        setCourses(
          localCourses.map((course: Trail) => ({
            label: course.name,
            value: course.trail_id,
          })),
        );
      }

      if (localUsers && localUsers.length) {
        setUsers(localUsers);
      }
    })();
  }, []);

  return (
    <EngagementContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/home">Home</Link>,
          <span>Relatórios</span>,
          <span>Engajamento</span>,
        ]}
      />

      <DefaultPageTitle>Engajamento</DefaultPageTitle>

      <DefaultFilterForm>
        <span className="entire-row">
          <label htmlFor="course">Cursos</label>
          <Select
            placeholder="Selecione os cursos"
            isMulti
            onChange={options =>
              setSelectedCourses(options.map(opt => opt.value))
            }
            options={courses}
          />
        </span>

        <span className="entire-row">
          <label htmlFor="course">Usuários</label>
          <Select
            placeholder="Selecione os usuários"
            isMulti
            onChange={options =>
              setSelectedUsers(options.map(opt => opt.value))
            }
            options={users}
          />
        </span>

        <span>
          <label htmlFor="startAtGte">Iniciado em</label>
          <input
            type="date"
            id="startAtGte"
            max={startCourseFinish}
            onChange={e => setStartCourseStart(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className="startAtLte">até</span>
          <input
            type="date"
            id="startAtLte"
            min={startCourseStart}
            onChange={e => setStartCourseFinish(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <label htmlFor="finishAtGte">Finalizado em</label>
          <input
            type="date"
            id="finishAtGte"
            max={finishCourseStart}
            onChange={e => setFinishCourseStart(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className="finishAtLte">até</span>
          <input
            type="date"
            id="finishAtLte"
            min={finishCourseFinish}
            onChange={e => setFinishCourseFinish(e.target.value)}
            onKeyDown={e => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span className="entire-row center">
          <DefaultButton onClick={() => generateReport('pdf')} type="button">
            Gerar Relatório (PDF)
          </DefaultButton>{' '}
          <DefaultButton onClick={() => generateReport('csv')} type="button">
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </EngagementContainer>
  );
};

export default Engagement;
