import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import Switch from 'react-switch';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import {
  getAllUsers,
  updateUserPermission,
  activateOrInactivateUser,
  updateUserPermissionProvider
} from '../../services/users';
import { UsersContainer, SearchContainer } from './style';
import { DefaultCreateButtonAndSearchFormContainer } from '../../components/DefaultCreateButtonAndSearchFormContainer';

const Users: React.FC = () => {
  const [users, setUsers] = useState([] as any[]);
  const [userName, setUserName] = useState('');
  const getUsers = async (filters?: any, event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const users = await getAllUsers({ ...filters, name: userName });
    setUsers(users || []);

    return users;
  };

  const updatePermission = useCallback(async (userId: string) => {
    try {
      await updateUserPermission(userId);

      Swal.fire({
        title: 'Sucesso',
        text: 'Permissão alterada com sucesso',
        icon: 'success',
      });

      await getUsers();
    } catch (err) {
      Swal.fire({
        title: 'Erro',
        text: 'Não foi alterar a permissão do usuário.',
        icon: 'error',
      });
    }
  }, []);

    const updatePermissionProvider = useCallback(async (userId: string) => {
        try {
            await updateUserPermissionProvider(userId);

            Swal.fire({
                title: 'Sucesso',
                text: 'Permissão alterada com sucesso',
                icon: 'success',
            });

            await getUsers();
        } catch (err) {
            Swal.fire({
                title: 'Erro',
                text: 'Não foi alterar a permissão do usuário.',
                icon: 'error',
            });
        }
    }, []);

  const activeOrInactiveUser = async (user_id: string, active: boolean) => {
    try {
      await activateOrInactivateUser(user_id);

      Swal.fire({
        title: 'Sucesso',
        text: `Usuário ${active ? 'inativado' : 'ativado'} com sucesso!`,
        icon: 'success',
      });

      await getUsers();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: `Erro ao ${active ? 'inativar' : 'ativar'} o usuário.`,
        icon: 'error',
      });
    }
  };

  const usersToBeShown = useMemo(() => {
    return users && users.length
      ? users.map(user => ({
          id: user.user_id,
          name: user.name,
          update_date: `${new Date(user.updated_at).getDay()}/${
            new Date(user.updated_at).getMonth() + 1
          }/${new Date(user.updated_at).getFullYear()}`,
          is_admin: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
              title={user.is_admin ? 'Remover permissões' : 'Tornar admin'}
            >
              <Switch
                onChange={() => updatePermission(user.user_id)}
                checked={user.is_admin}
              />
            </div>
          ),
          is_active: (
            <div title={user.is_active ? 'Inativar usuário' : 'Ativar usuário'}>
              <Switch
                onChange={() =>
                  activeOrInactiveUser(user.user_id, user.is_active)
                }
                checked={user.is_active}
              />
            </div>
          ),
          is_provider: (
            <div title={user.is_provider ? 'Remover permissão' : 'Tornar fornecedor'}>
                <Switch
                    onChange={() => updatePermissionProvider(user.user_id)}
                    checked={user.is_provider}
                />
            </div>
          ),
        }))
      : [];
  }, [users, updatePermission]);

  useEffect(() => {
    if (!userName) {
      getUsers();
    }
  }, [userName]);

  return (
    <UsersContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Usuários</span>]}
      />
      <DefaultPageTitle>Usuários</DefaultPageTitle>

      <DefaultFilterForm
        style={{
          display: 'grid',
          gridTemplateColumns: '95% 5%',
          gap: '10px',
          margin: '0 ',
        }}
      ></DefaultFilterForm>
      <SearchContainer>
        <p>{users.filter(user => user.is_active).length} Usuários ativos.</p>
        <DefaultCreateButtonAndSearchFormContainer
          style={{ justifyContent: 'flex-end' }}
        >
          <form
            onSubmit={e => getUsers(undefined, e)}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
              gap: '10px',
            }}
          >
            <DefaultInput
              type="search"
              placeholder="Digite sua pesquisa aqui..."
              value={userName}
              onChange={e => setUserName(e.target.value)}
              style={{ margin: 0 }}
            />
            <DefaultButton
              className="small"
              style={{ margin: 0 }}
              type="submit"
            >
              <AiOutlineSearch size={24} />
            </DefaultButton>
          </form>
        </DefaultCreateButtonAndSearchFormContainer>
      </SearchContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Data de Atualização</span>,
            propName: 'update_date',
          },
          {
            headerLabel: <span>Usuário Ativo</span>,
            propName: 'is_active',
          },
          {
            headerLabel: <span>Admin</span>,
            propName: 'is_admin',
          },
          {
            headerLabel: <span>Fornecedor</span>,
            propName: 'is_provider',
          },
        ]}
        items={usersToBeShown}
        emptyListMessage={'Não foram encontrados usuários cadastrados!'}
      />
    </UsersContainer>
  );
};

export default Users;
