import styled from 'styled-components';

const SelectLessonContainer = styled.div`
  height: calc(100% - 60px);
  overflow-y: auto;
  width: 100%;
  position: relative;
  border-bottom: solid 1px var(--default-dark-gray);
  > div {
    width: 100%;
  }
  & input[type='checkbox'] {
    display: block;
    margin: 0 auto;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
  button {
    margin: 20px 30px 0 auto;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export { SelectLessonContainer };
