import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';

import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import accessKeys from '../../models/accessKeys';
import { getAccessKeys } from '../../services/register-key';
import { KeysContainer } from './style';
import { DefaultCreateButtonAndSearchFormContainer } from '../../components/DefaultCreateButtonAndSearchFormContainer';

const AccessKeys: React.FC = () => {
  const history = useHistory();

  const [registerKeys, setRegisterKeys] = useState([] as accessKeys[]);

  const getRegisterKeys = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const registerKeys = await getAccessKeys();
    setRegisterKeys(registerKeys || []);
  };

  const formatData = (data: string) => {
    function addZero(number: number) {
      if (number <= 9) return '0' + number;
      else return number;
    }
    let newData = new Date(data);
    let formatData =
      addZero(newData.getDate()) +
      '/' +
      addZero(newData.getMonth() + 1) +
      '/' +
      addZero(newData.getFullYear());
    return formatData;
  };

  const createKeys = async () => {
    history.push('/create-accessKey');
  };

  const testExpiration = (data: string) => {
    const currentDate = new Date();
    const expirationData = new Date(data);
    if (currentDate > expirationData) return true;
    return false;
  };

  const keysToBeShown = useMemo(() => {
    return registerKeys && registerKeys.length
      ? registerKeys.map(accessKey => ({
          accessKeys: accessKey.value,
          expiration: testExpiration(accessKey.expiration_time) ? (
            <span className="expiration">
              {formatData(accessKey.expiration_time)}
            </span>
          ) : (
            <span className="noExpiration">
              {formatData(accessKey.expiration_time)}
            </span>
          ),
          creation: (
            <span className="noExpiration">
              {formatData(accessKey.created_at)}
            </span>
          ),
        }))
      : [];
  }, [registerKeys]);

  useEffect(() => {
    getRegisterKeys();
  }, []);
  return (
    <KeysContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Chave de acesso</span>,
        ]}
      />
      <DefaultPageTitle>Chave de acesso</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createKeys}>
            Criar chave de acesso
          </DefaultButton>
        </CreateButtonDefaultContainer>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Chave de Acesso</span>,
            propName: 'accessKeys',
          },
          {
            headerLabel: <span>data de criação</span>,
            propName: 'creation',
          },
          {
            headerLabel: <span>data de expiração</span>,
            propName: 'expiration',
          },
        ]}
        items={keysToBeShown}
        emptyListMessage={'Não foram encontradas chaves de acesso cadastradas!'}
      />
    </KeysContainer>
  );
};

export default AccessKeys;
