import convertToContent from "../helpers/converters/convert-content";
import httpClient from "../http-client";
import Content from "../models/content";
import ContentTrailForCreate from "../models/for-create/content-trail";
import ContentForUpdate from "../models/for-update/content";
import ContentFromReponse from "../models/from-api-response/content";
import { uploadFile } from "./files";

const getContent = async (contentId: string) => {
  const content = (await httpClient.get(`contents/${contentId}`)).data;
  return content;
};

const getAllContents = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const contents = (
    await httpClient.get<ContentFromReponse[]>("contents", {
      params: {
        ...filters,
        is_library: false,
      },
    })
  ).data;

  let convertedContents = [] as Content[];
  if (contents && contents.length) {
    convertedContents = contents.map(convertToContent);
  }

  return convertedContents;
};

const createContent = async (newContent: ContentTrailForCreate) => {
  const createContentResponse = (
    await httpClient.post<ContentFromReponse>(`contents/`, newContent)
  ).data;

  return createContentResponse;
};

const updateContent = async (contentId: string, newContent: any) => {
  await httpClient.put(`contents/${contentId}`, newContent);
};

const deleteContent = async (contentId: string) => {
  await httpClient.delete(`contents/${contentId}`);
};

const changeContentThumbnail = async (
  contentId: string,
  formData: FormData
) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`contents/${contentId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const activateOrInactivateContent = async (
  contentId: string,
  activate: boolean
) => {
  await httpClient.put(`contents/${contentId}/active`, { is_active: activate });
};

export {
  getAllContents,
  deleteContent,
  updateContent,
  createContent,
  changeContentThumbnail,
  getContent,
  activateOrInactivateContent,
};
