import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Question from '../../../../models/question';
import { SelectQuestionsContainer } from './style';
import { getQuestions as getQuestionsService } from '../../../../services/questions';
import DefaultButton from '../../../../components/DefaultButton';
import DefaultTable from '../../../../components/DefaultTable';
import DefaultInput from '../../../../components/DefaultInput';
import { AiOutlineSearch } from 'react-icons/ai';

interface SelectQuestionsProps {
  selectedQuestions: Question[];
  onApplySelection: (questions: Question[]) => void;
}

const SelectQuestions: React.FC<SelectQuestionsProps> = ({
  selectedQuestions: initialSelectedQuestions,
  onApplySelection,
}) => {
  const [questions, setQuestions] = useState([] as Question[]);
  const [questionEnunciated, setQuestionEnunciated] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState([] as Question[]);

  const getQuestions = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    let localQuestions = await getQuestionsService();

    localQuestions = (localQuestions || []).filter(
      q =>
        !questionEnunciated ||
        (q.enunciated || '')
          .toLocaleLowerCase()
          .includes(questionEnunciated.toLocaleLowerCase()),
    );

    setQuestions(localQuestions);
  };

  const handleCheckQuestion = useCallback(
    (event: React.FormEvent<HTMLInputElement>, question: Question) => {
      if (
        !selectedQuestions.find(
          selectedQuestion => selectedQuestion.id === question.id,
        )
      ) {
        selectedQuestions.push(question);
      } else {
        const indexOfQuestion = selectedQuestions
          .map(q => q.id)
          .indexOf(question.id);
        if (
          indexOfQuestion > -1 &&
          indexOfQuestion < selectedQuestions.length
        ) {
          selectedQuestions.splice(indexOfQuestion, 1);
        }
      }

      setSelectedQuestions([...selectedQuestions]);
    },
    [questions, selectedQuestions],
  );

  const applySelection = (event: React.FormEvent<HTMLButtonElement>) => {
    onApplySelection(selectedQuestions);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    if (initialSelectedQuestions) {
      setSelectedQuestions(initialSelectedQuestions);
    }
  }, []);

  const questionsToShow = useMemo(() => {
    return questions && questions.length
      ? questions.map(question => {
          const shouldBeChecked = selectedQuestions.some(
            q => q.id === question.id,
          );

          return {
            ...question,
            check: (
              <input
                type="checkbox"
                value={question.id}
                defaultChecked={shouldBeChecked}
                onChange={e => handleCheckQuestion(e, question)}
              />
            ),
          };
        })
      : [];
  }, [questions, handleCheckQuestion, selectedQuestions]);

  useEffect(() => {
    if (!questionEnunciated) {
      getQuestions();
    }
  }, [questionEnunciated]);

  return (
    <SelectQuestionsContainer>
      <DefaultButton
        type="button"
        style={{ position: 'fixed', right: '12.5%', bottom: '11%' }}
        onClick={applySelection}
      >
        Aplicar Seleção
      </DefaultButton>

      <form
        onSubmit={getQuestions}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          gap: '10px',
        }}
      >
        <DefaultInput
          type="search"
          placeholder="Digite sua pesquisa aqui..."
          value={questionEnunciated}
          onChange={e => setQuestionEnunciated(e.target.value)}
          style={{ margin: 0, width: '320px' }}
        />
        <DefaultButton className="small" style={{ margin: 0 }} type="submit">
          <AiOutlineSearch size={24} />
        </DefaultButton>
      </form>

      <DefaultTable
        usePagination={false}
        headersConfig={[
          {
            headerLabel: <span>Enunciado</span>,
            propName: 'enunciated',
          },
          {
            headerLabel: <span>Tipo</span>,
            propName: 'questionType',
          },
          { headerLabel: <span>Selecionar</span>, propName: 'check' },
        ]}
        items={questionsToShow}
        emptyListMessage="Não foram encontradas questões cadastradas."
      />
    </SelectQuestionsContainer>
  );
};

export default SelectQuestions;
