import styled from 'styled-components';

const FinishedCoursesContainer = styled.div`
  label,
  .label {
    width: 100px;
    margin-bottom: 10px;
    font-size: var(--font-small);
    color: var(--primary-color);
    font-weight: 700;
    min-width: 100px;
  }

  input {
    border-radius: 5px;
    border: solid 1px var(--default-dark-gray);
    outline: none;
    font-size: var(--font-small);
    width: 100%;
    height: 44px;
    padding: 0 10px;
    color: var(--default-gray);
  }
  button {
    margin-top: 40px;
    &:first-of-type {
      margin-right: 20px;
    }
  }
`;

export { FinishedCoursesContainer };
