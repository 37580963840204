import styled from 'styled-components';

const ProfileContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  h1{ 
    font-size: 40px;
    margin-bottom: 20px;
    color: var(--default-title-color);
  }

  img{
    opacity:0.2;
  }
`;

export {
  ProfileContainer
}