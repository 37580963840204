import httpClient from '../http-client';
import UserFromResponse from '../models/from-api-response/user';

const getActualUser = async () => {
  return (await httpClient.get('users/me')).data;
};

const getAllUsers = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const users = (
    await httpClient.get<UserFromResponse[]>(`users/`, { params: filters })
  ).data;

  if (users && users.length) {
    return users;
  }
};

const getUsersForSelect = async () => {
  const users = (await httpClient.get<UserFromResponse[]>(`users/`)).data;

  if (users && users.length) {
    return users.map(user => ({
      value: user.user_id,
      label: user.name,
    }));
  }

  return [] as { value: string; label: string }[];
};

const updateUserPermission = async (user_id: string) => {
  await httpClient.patch(`users/set-user-admin`, { user_id });
};

const updateUserPermissionProvider = async (user_id: string) => {
  await httpClient.patch(`users/set-user-provider`, { user_id });
};

const activateOrInactivateUser = async (user_id: string): Promise<any> => {
  const updatedUser = (
    await httpClient.patch(`users/active-or-inactive/${user_id}`)
  ).data;

  return updatedUser;
};

export {
  getUsersForSelect,
  getActualUser,
  updateUserPermission,
  getAllUsers,
  activateOrInactivateUser,
  updateUserPermissionProvider
};
