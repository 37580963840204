import Article from "../../models/article";
import ArticleFromResponse from "../../models/from-api-response/article";

const convertToArticle = (article: ArticleFromResponse): Article => {
  return {
    id: article.article_id,
    title: article.title,
    description: article.description,
    content: article.content,
    categories: [],
    tags: [],
    active: article.is_active,
    aproved: article.status === "approved",
    pending: article.status === "pending",
    reproved: article.status === "reproved",
    authorName: article.user.user_name,
    thumbnail: article.thumbnail,
    createdAt: new Date(article.created_at),
  };
};

export default convertToArticle;
