import httpClient from '../http-client';
import Author from '../models/author';
import AuthorForCreate from '../models/for-create/authors';
import AuthorForUpdate from '../models/for-update/authors';
import { uploadFile } from './files';

const getAuthor = async (authorId: string) => {
  const author = (await httpClient.get<Author>(`authors/${authorId}`)).data;

  let convertedAuthor = {} as Author;
  //if (author && Object.keys(author).length) {
  //convertedAuthor = convertToContent(author);

  //}

  return author;
};

const getAllAuthors = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const authors = (await httpClient.get<any[]>('authors', { params: filters }))
    .data;

  //let convertedContents = [] as Author[];
  if (authors && authors.length) {
    return authors;
  }
};

const createAuthor = async (newAuthor: AuthorForCreate) => {
  const createAuthorResponse = (
    await httpClient.post<any>(`authors/`, newAuthor)
  ).data;
  return createAuthorResponse;
};

const updateAuthor = async (authorId: string, newInfo: AuthorForUpdate) => {
  await httpClient.put(`authors/${authorId}`, newInfo);
};

const deleteAuthor = async (contentId: string) => {
  await httpClient.delete(`authors/${contentId}`);
};

const changeAuthorThumbnail = async (authorId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`authors/${authorId}/avatar`, {
    avatar_url: uploadedFile.reference,
  });
};

const activateOrInactivateContent = async (
  contentId: string,
  activate: boolean,
) => {
  await httpClient.put(`contents/${contentId}/active`, { is_active: activate });
};

export {
  getAllAuthors,
  deleteAuthor,
  updateAuthor,
  createAuthor,
  changeAuthorThumbnail,
  getAuthor,
  activateOrInactivateContent,
};
