import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Question from '../../models/question';
import { QuestionsContainer } from './style';
import {
  getQuestions as getQuestionsService,
  deleteQuestion as removeQuestionService,
  activateOrInactivateQuestion as activateOrInactivateQuestionService,
} from '../../services/questions';
import Swal from 'sweetalert2';

const Questions: React.FC = () => {
  const history = useHistory();
  const [questions, setQuestions] = useState([] as Question[]);

  const createQuestion = () => {
    history.push('/exams-questions/create-question');
  };

  const editQuestion = useCallback(
    (questionId: string) => {
      history.push(`/exams-questions/edit-question/${questionId}`);
    },
    [history],
  );

  const removeQuestion = useCallback((questionId: string) => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Tem certeza que deseja remover esta questão?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      icon: 'question',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await removeQuestionService(questionId);

          await refreshQuestions();
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: 'Erro ao remover questão. ' + error.message,
            icon: 'error',
          });
        }
      }
    });
  }, []);

  const activateOrInactivateQuestion = useCallback(
    (questionId: string, activate: boolean) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${
          activate ? 'ativar' : 'inativar'
        } esta questão?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        icon: 'question',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateQuestionService(questionId);

            await refreshQuestions();
          } catch (error) {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao ${activate ? 'ativar' : 'inativar'} questão. ${
                error.message
              }`,
              icon: 'error',
            });
          }
        }
      });
    },
    [],
  );

  const refreshQuestions = async () => {
    setQuestions([]);
    const localQuestions = await getQuestionsService();
    if (localQuestions && localQuestions.length) {
      setQuestions(localQuestions);
    }
  };

  useEffect(() => {
    refreshQuestions();
  }, []);

  const questionsToShow = useMemo(() => {
    return questions && questions.length
      ? questions.map(question => ({
          ...question,
          enabled: question.enabled ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() => editQuestion(question.id)}
                className="small info"
                title="Editar Questão"
              >
                <BiEdit />
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() =>
                  activateOrInactivateQuestion(question.id, !question.enabled)
                }
                className="small warning"
                title={
                  (question.enabled ? 'Habilitar' : 'Desabilitar') + ' Questão'
                }
              >
                {question.enabled ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => removeQuestion(question.id)}
                className="small danger"
                title="Excluir Questão"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [questions, activateOrInactivateQuestion, editQuestion, removeQuestion]);

  return (
    <QuestionsContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Provas e Questões</span>,
          <span>Questões</span>,
        ]}
      />

      <DefaultPageTitle>Questões</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createQuestion}>Criar Questão</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Enunciado</span>,
            propName: 'enunciated',
          },
          {
            headerLabel: <span>Habilitado</span>,
            propName: 'enabled',
            order: (_: any[], currentOrder: string) => {
              setQuestions([
                ...questions.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes('enabled') &&
                    currentOrder.includes('asc');
                  if (isDescendant) {
                    return a.enabled ? 1 : -1;
                  } else {
                    return a.enabled ? -1 : 1;
                  }
                }),
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={questionsToShow}
        emptyListMessage="Não foram encontradas questões cadastradas!"
      />
    </QuestionsContainer>
  );
};

export default Questions;
