import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import { hideModal, showModal } from "../../helpers/modal";
import {
  ContentThumbnail,
  CreateAndEditCategoryContainer,
  ThumbnailUploadContainer,
  CategoryColor,
} from "./style";
import CutImage from "../../components/CutImage";
import checkEmptyString from "../../helpers/check-empty-string";
import {
  createCategory as createCategoryService,
  getCategory,
  changeCategoryLogo as changeCategoryLogoService,
  updateCategory as updateCategoryService,
  deleteCategory,
} from "../../services/categories";
import { SwatchesPicker } from "react-color";
import getErrorMessage from "../../helpers/get-error-message";

interface CreateAndEditCategoryProps {
  categoryId: string;
}

const CreateAndEditCategory: React.FC = () => {
  const { categoryId } = useParams<CreateAndEditCategoryProps>();

  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [logoFileToUpload, setLogoFileToUpload] = useState<File>();
  const [logo, setLogo] = useState("");
  const [color, setColor] = useState("");

  const createCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título válido para a categoria.");
      }

      const createdCategory = await createCategoryService({
        title: title,
        description: description.trim().length ? description : undefined,
        info: {
          color: color || "#000000",
        },
      });

      if (logoFileToUpload) {
        try {
          await changeCategoryLogo(
            logoFileToUpload,
            createdCategory.category_id
          );
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          await deleteCategory(createdCategory.category_id);

          throw new Error("Erro ao fazer upload da logo. " + errorMessage);
        }
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Categoria criada com sucesso!",
        icon: "success",
      });

      goToCategories();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar a categoria. " + errorMessage,
        icon: "error",
      });
    }
  };

  const updateCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título válido para a categoria.");
      }

      await updateCategoryService(categoryId, {
        title: title,
        description: description || undefined,
        info: {
          color: color,
        },
      });

      if (logoFileToUpload) {
        try {
          await changeCategoryLogo(logoFileToUpload, categoryId);
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          throw new Error("Erro ao fazer upload da logo. " + errorMessage);
        }
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Conteúdo editado com sucesso!",
        icon: "success",
      });

      goToCategories();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar o conteúdo. " + errorMessage,
        icon: "error",
      });
    }
  };

  const changeCategoryLogo = async (
    localTLogoFileToUpload: File,
    localCategoryId: string
  ) => {
    const formData = new FormData();
    formData.append("file", localTLogoFileToUpload);
    formData.append(
      "name",
      `content_thumbnail_${localCategoryId}.${localTLogoFileToUpload.type}`
    );
    formData.append("description", `logo do conteúdo de id ${localCategoryId}`);

    await changeCategoryLogoService(localCategoryId, formData);
  };

  const selectLogo = () => {
    showModal(
      "Selecionar Imagem da Logo",
      <CutImage aspect={1} onCutImage={onCutLogo} />
    );
  };

  const onCutLogo = (file: File) => {
    if (file) {
      setLogoFileToUpload(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setLogo(`${reader.result}`);
      hideModal();
    }
  };

  const goToCategories = () => {
    history.push("/categories");
  };

  const getContent = useCallback(async () => {
    if (categoryId) {
      const content = await getCategory(categoryId);
      if (content && Object.keys(content).length) {
        setTitle(content.title);
        setDescription(content.description);
        setLogo(content.logo);
        setThumbnail(content.thumbnail);
        setColor(content.info.color);
      }
    }
  }, [categoryId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (categoryId) {
      return true;
    }

    return false;
  }, [categoryId]);

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/categories">Categorias</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Categorias</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Categorias
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
            required
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="reference">Logo</label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectLogo}>
              Selecionar Logo
            </DefaultButton>

            {logo && <ContentThumbnail src={logo} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Cor da Categoria</label>
          <CategoryColor color={color} />
          <SwatchesPicker onChange={(color) => setColor(color.hex)} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToCategories}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) =>
              isEditting ? updateCategory(e) : createCategory(e)
            }
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default CreateAndEditCategory;