import convertToEvent from "../helpers/converters/convert-event";
import httpClient from "../http-client";
import Event from "../models/event";
import EventFromReponse from "../models/from-api-response/event";
import { uploadFile } from "./files";
import EventForCreate from "../models/for-create/event";
import EventForUpdate from "../models/for-update/event";

const getEvent = async (eventId: string) => {
  const event = (
    await httpClient.get<EventFromReponse>(`events/${eventId}`)
  ).data;

  let convertedEvent = {} as Event;
  if (event && Object.keys(event).length) {
    convertedEvent = convertToEvent(event);
  }

  return convertedEvent;
};

const getAllEvents = async () => {
  const events = (await httpClient.get<EventFromReponse[]>("events")).data;

  let convertedEvents = [] as Event[];
  if (events && events.length) {
    convertedEvents = events.map(convertToEvent);
  }

  return convertedEvents;
};

const deleteEvent = async (eventId: string) => {
  await httpClient.delete(`events/${eventId}`);
};

const createEvent = async (newEvent: EventForCreate) => {
  const createEventResponse = (
    await httpClient.post<EventFromReponse>(`events/`, newEvent)
  ).data;

  return createEventResponse;
};

const updateEvent = async (eventId: string, newEvent: EventForUpdate) => {
  await httpClient.put(
    `events/${eventId}`,
    newEvent
  );
};

const changeEventThumbnail = async (eventId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`events/${eventId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const activateOrInactivateEvent = async (
  eventId: string,
  activate: boolean
) => {
  await httpClient.patch(
    `events/${eventId}/active`,
    { is_active: activate }
  );
};

export {
  getAllEvents,
  deleteEvent,
  getEvent,
  createEvent,
  updateEvent,
  changeEventThumbnail,
  activateOrInactivateEvent,
};
