import convertToArticle from "../helpers/converters/convert-article";
import httpClient from "../http-client";
import Article from "../models/article";
import ArticleFromResponse from "../models/from-api-response/article";

const getArticles = async () => {
  const articles = (await httpClient.get<ArticleFromResponse[]>(`/articles`))
    .data;

  let convertedArticles = [] as Article[];
  if (articles && articles.length) {
    convertedArticles = articles.map((article) => convertToArticle(article));
  }

  return convertedArticles;
};

const aproveArticle = async (articleId: string) => {
  await httpClient.patch(`articles/${articleId}/approved`);
};

const reproveArticle = async (articleId: string, feedback: string) => {
  await httpClient.patch(`articles/${articleId}/reproved`, { feedback });
};

export { getArticles, aproveArticle, reproveArticle };
