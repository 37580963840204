import styled from 'styled-components';

const DefaultCreationForm = styled.form`
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  & .react-datepicker-wrapper {
    flex-grow: 1;

    input {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      border: solid 1px var(--default-dark-gray);
      padding: 10px 15px;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

export const DefaultCreationFormGroup = styled.div`
  width: 100%;

  & + div {
    margin-top: 16px;
  }

  label {
    min-width: 145px;
    margin-bottom: 12px;
    font-size: var(--font-small);
    color: var(--primary-color);
    font-weight: 700;

    li {
      padding-top: 10px;
      color: var(--default-gray);
      font-weight: 300;
    }
  }

  & > .editor-terms {
    min-width: 145px;
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const DefaultCreationFormButtonGroup = styled.div`
  width: 100%;
  margin: 10px 0;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default DefaultCreationForm;
