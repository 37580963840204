import styled from "styled-components";

const ArticlePreviewContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

const ArticlPreviewThumbnailContainer = styled.div``;

const ArticlPreviewThumbnail = styled.img`
  display: block;
  margin: 0 auto;
`;

const ArticlPreviewContentContainer = styled.div`
  h1,
  h2 {
    margin-bottom: 10px;
  }
  h1 {
    font-size: var(--font-large);
  }
  h2 {
    font-size: var(--font-medium);
  }
  p {
    font-size: 16px;
    margin: 5px 0;
  }

  @media screen and (min-width: 998px) {
    max-width: 640px;
    margin: 20px auto 0;
    padding: 0 20px;
  }

  &.mdeditor,
  &.mdarea {
    ul {
      list-style-type: disc;
      list-style-position: inside;
    }
    ol {
      list-style-type: decimal;
      list-style-position: inside;
    }
    ul ul,
    ol ul {
      list-style-type: circle;
      list-style-position: inside;
      margin-left: 15px;
    }
    ol ol,
    ul ol {
      list-style-type: lower-latin;
      list-style-position: inside;
      margin-left: 15px;
    }
    blockquote {
      position: relative;
      margin: 16px 0;
      padding: 5px 8px 5px 30px;
      background: none repeat scroll 0 0 rgba(102, 128, 153, 0.05);
      border: none;
      color: #333;
      border-left: 10px solid #d6dbdf;
    }
  }
`;

export {
  ArticlePreviewContainer,
  ArticlPreviewThumbnailContainer,
  ArticlPreviewThumbnail,
  ArticlPreviewContentContainer,
};
