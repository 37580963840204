import Content from "../../models/content";
import ContentFromReponse from "../../models/from-api-response/content";

const convertToContent = (course: ContentFromReponse): Content => {
  return {
    id: course.content_id,
    title: course.title,
    description: course.description,
    duration: +course.duration,
    reference: course.reference,
    active: course.is_active,
    thumbnail: course.thumbnail,
    type: course.type,
    info: course.info,
  };
};

export default convertToContent;
