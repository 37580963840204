import Event from "../../models/event";
import EventFromResponse from "../../models/from-api-response/event";

const convertToEvent = (event: EventFromResponse): Event => {
  return {
    id: event.event_id,
    title: event.title,
    description: event.description,
    address: event.local,
    startDate: new Date(event.start_date),
    endDate: new Date(event.end_date),
    observation: event.observation,
    price: event.price,
    thumbnail: event.thumbnail,
    active: event.is_active,
  };
};

export default convertToEvent;
