import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import SwitchElement from 'react-switch';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BiDownload, BiTrash } from 'react-icons/bi';
import Swal from 'sweetalert2';
import 'scorm-again';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import {
  createExtraMaterial as createExtraMaterialService,
  deleteExtraMaterial as deleteExtraMaterialService,
} from '../../services/extra-materials';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import SelectSambaVideosContent from '../../components/SelectSambaVideosContent';
import { hideModal, showModal } from '../../helpers/modal';
import MediaFromResponse from '../../models/from-api-response/media';
import {
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  ExtraMaterial,
  ExtraMaterialList,
  ExtraMaterialsInput,
  SwitchContainer,
} from './style';
import {
  createContent as createContentService,
  getContent as getContentService,
  updateContent as updateContentService,
} from '../../services/contents-trail';
import checkEmptyString from '../../helpers/check-empty-string';
import { uploadFile } from '../../services/files';
import { UpdatedAtLabel } from '../../components/UpdatedAt';
import UploadScorm from '../../components/UploadScorm';

interface CreateAndEditContentProps {
  contentId: string;
}

declare let window: any;

const CreateAndEditContent: React.FC = () => {
  const inputRef = useRef<any>(null);
  const [extraMaterials, setExtraMaterials] = useState<any>([] as any[]);
  const [extraMaterialsToBeDeleted, setExtraMaterialsToBeDeleted] =
    useState<any>([] as any[]);
  const { contentId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reference, setReference] = useState('');
  const [duration, setDuration] = useState(0);
  const [type, setType] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [switchState, setSwitchState] = useState<string>('video');
  const [isScormVersion12, setIsScormVersion12] = useState<boolean>(false);
  const [durationMin, setDurationMin] = useState(0);

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl);
          setDuration(firstFile.fileInfo.duration / 1000);
          setDurationMin(firstFile.fileInfo.duration / 1000);
          setType(firstFile.qualifier);
        }
      }
    }

    hideModal();
  };

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título valido para o conteúdo.');
      }

      if (!reference) {
        throw new Error('Selecione um conteúdo antes.');
      }

      if (switchState === 'scorm') {
        setDuration(durationMin * 60);
      }

      const createdContent = await createContentService({
        title: title,
        description: description.trim().length ? description : undefined,
        duration: duration,
        reference: reference,
        type:
          switchState === 'scorm'
            ? switchState.toUpperCase()
            : type.toLocaleUpperCase(),
        is_library: false,
        info:
          switchState === 'scorm'
            ? { scorm_version: isScormVersion12 ? '1.2' : '2004' }
            : undefined,
      });

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter(
          (extraMaterial: any) => !extraMaterial.alreadySaved,
        )) {
          const formData = new FormData();
          formData.append('file', material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name }),
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(
          createdContent.content_id,
          uploadedMaterials,
        );
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo criado com sucesso!',
        icon: 'success',
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o conteúdo. ' + error.message,
        icon: 'error',
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título valido para o conteúdo.');
      }

      if (!reference) {
        throw new Error('Selecione um conteúdo antes.');
      }
      
      await updateContentService(contentId, {
        title: title,
        description: description || undefined,
        duration: switchState === 'scorm' ? durationMin * 60 : Number(duration),
        reference: reference,
        type:
          switchState === 'scorm'
            ? switchState.toUpperCase()
            : type.toLocaleUpperCase(),
        is_active: true,
        is_library: false,
        info:
          switchState === 'scorm'
            ? { scorm_version: isScormVersion12 ? '1.2' : '2004' }
            : undefined,
      });

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter(
          (extraMaterial: any) => !extraMaterial.alreadySaved,
        )) {
          const formData = new FormData();
          formData.append('file', material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name }),
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(contentId, uploadedMaterials);
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Curso editado com sucesso!',
        icon: 'success',
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o conteúdo. ' + error.message,
        icon: 'error',
      });
    }
  };

  const selectContent = () => {
    showModal(
      'Selecionar Conteúdo',
      <SelectSambaVideosContent onSelectContent={onSelectContent} />,
    );
  };

  const goToContents = () => {
    history.push('/course/contents');
  };

  const getContent = useCallback(async () => {
    if (contentId) {
      const content = await getContentService(contentId);

      if (content && Object.keys(content).length) {
        setTitle(content.content.title);
        setDescription(content.content.description);
        setReference(content.content.reference);
        setDuration(content.content.duration);
        setDurationMin(content.content.duration / 60);
        setType(content.content.type);

        if (content.content.type.toUpperCase() === 'SCORM') {
          setSwitchState('scorm');
          if (content.content.info.scorm_version === '1.2') {
            setIsScormVersion12(true);
          } else {
            setIsScormVersion12(false);
          }
        }

        

        if (content.content?.updated_at) {
          let date = new Date(content.content.updated_at);
          setUpdatedAt(date.toLocaleString());
        }

        const localExtraMaterials =
          content.content.extra_materials &&
          content.content.extra_materials.length &&
          content.content.extra_materials.map((extraMaterial: any) => ({
            id: extraMaterial.extra_material_id,
            name: extraMaterial.name,
            alreadySaved: true,
            reference: extraMaterial.reference,
          }));

        setExtraMaterials(localExtraMaterials);
      }
    }
  }, [contentId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (contentId) {
      return true;
    }

    return false;
  }, [contentId]);

  const addExtraMaterial = (event: any) => {
    event.preventDefault();

    if (event) {
      let file = event.target.files[0];
      file.alreadySaved = false;

      setExtraMaterials([...(extraMaterials || []), file]);
    }
  };

  const removeExtraMaterial = (extraMaterialToBeRemoved: any) => {
    if (extraMaterialToBeRemoved.alreadySaved) {
      setExtraMaterialsToBeDeleted([
        ...(extraMaterialsToBeDeleted || []),
        extraMaterialToBeRemoved,
      ]);
    }

    let localExtraMaterials = [...(extraMaterials || [])];
    localExtraMaterials = localExtraMaterials.filter(
      extraMaterial => extraMaterial !== extraMaterialToBeRemoved,
    );

    setExtraMaterials(localExtraMaterials);
  };

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/course/contents">Conteúdos</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Conteúdos</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Conteúdo
        {updatedAt && (
          <UpdatedAtLabel> - Última atualização em {updatedAt}</UpdatedAtLabel>
        )}
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={e => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description}
            onChange={e => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Conteúdo
          </label>

          <SwitchContainer>
            <SwitchElement
              onChange={checked => {
                setSwitchState(checked ? 'video' : 'scorm');
                setReference('');
              }}
              checked={switchState === 'video'}
              uncheckedIcon={false}
              checkedIcon={false}
              offColor="#0054a6"
              onColor="#0054a6"
            />

            <label>{switchState === 'video' ? 'VÍDEO' : 'SCORM'}</label>
          </SwitchContainer>

          <ContentUploadOrSelectContainer>
            {switchState === 'video' ? (
              <DefaultButton type="button" onClick={selectContent}>
                Selecionar Conteúdo
              </DefaultButton>
            ) : (
              <UploadScorm
                setReference={setReference}
                setIsScormVersion12={setIsScormVersion12}
              />
            )}

            {reference ? (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={reference}
                frameBorder={0}
              ></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        {switchState === 'scorm' && (
          <DefaultCreationFormGroup>
            <label htmlFor="duration">Duração</label>
            <DefaultInput
              type="number"
              value={durationMin}
              onChange={e => setDurationMin(parseInt(e.target.value))}
              id="duration"
            />
          </DefaultCreationFormGroup>
        )}
        <DefaultCreationFormGroup>
          <label>Materiais de apoio</label>
          <label htmlFor="extra-materials" className="extra-materials">
            Selecionar material
          </label>
          <ExtraMaterialsInput
            type="file"
            onChange={e => addExtraMaterial(e)}
            ref={inputRef}
            id="extra-materials"
          />
          <ExtraMaterialList>
            {extraMaterials && extraMaterials.length ? (
              extraMaterials.map((material: any, index: number) => (
                <ExtraMaterial key={index}>
                  <h5>{material.name}</h5>
                  {material.reference ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={material.reference}
                    >
                      <BiDownload size={14} />
                    </a>
                  ) : (
                    <></>
                  )}

                  <button
                    onClick={() => removeExtraMaterial(material)}
                    type="button"
                  >
                    <BiTrash size={14} />
                  </button>
                </ExtraMaterial>
              ))
            ) : (
              <p>Não foi encontrado nenhum material de apoio.</p>
            )}
          </ExtraMaterialList>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToContents}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={e => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditContent;
