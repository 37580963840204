import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultCreateButtonAndSearchFormContainer } from '../../components/DefaultCreateButtonAndSearchFormContainer';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import convertSecondsToTimeText from '../../helpers/seconds-to-time-text';
import Content from '../../models/content';
import {
  deleteContent,
  getAllContents,
  activateOrInactivateContent as activateOrInactivateContentService,
} from '../../services/contents';
import { ContentsContainer } from './style';

const Contents: React.FC = () => {
  const history = useHistory();

  const [contents, setContents] = useState([] as Content[]);
  const [contentName, setContentName] = useState('');

  const getContents = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const localContents = await getAllContents({ title: contentName });
    setContents(localContents || []);
  };

  const editContent = useCallback(
    (contentId: string) => {
      history.push(`edit-content/${contentId}`);
    },
    [history],
  );

  const removeContent = useCallback(async (contentId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este curso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteContent(contentId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Curso excluído com sucesso!',
          });

          await getContents();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir curso. ${
              error.response && error.response.status === 423
                ? 'Este curso já está associado a uma aula!'
                : error.message
            }`,
          });
        }
      }
    });
  }, []);

  const createContent = async () => {
    history.push('create-content');
  };

  const activateOrInactivateContent = useCallback(
    async (contentId: string, activate: boolean) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${
          activate ? 'ativar' : 'inativar'
        } este curso?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateContentService(contentId, activate);

            getContents();
          } catch (error) {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao ${activate ? 'ativar' : 'inativar'} curso. ${
                error.response && error.response.status === 423
                  ? 'Este curso já está associado a uma aula!'
                  : error.message
              }`,
            });
          }
        }
      });
    },
    [],
  );

  const contentsToBeShown = useMemo(() => {
    return contents && contents.length
      ? contents.map(content => ({
          title: content.title,
          description: content.description,
          duration: convertSecondsToTimeText(content.duration),
          active: content.active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() => editContent(content.id)}
                className="small info"
                title="Editar Curso"
              >
                <BiEdit />
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() =>
                  activateOrInactivateContent(content.id, !content.active)
                }
                className="small warning"
                title={(content.active ? 'Inativar' : 'Ativar') + ' Curso'}
              >
                {content.active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => {
                  removeContent(content.id);
                }}
                className="small danger"
                title="Excluir Curso"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [contents, editContent, removeContent, activateOrInactivateContent]);

  useEffect(() => {
    getContents();
  }, []);

  useEffect(() => {
    if (!contentName) {
      getContents();
    }
  }, [contentName]);

  return (
    <ContentsContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Dashboard</Link>, <span>Cursos</span>]}
      />

      <DefaultPageTitle>Cursos</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createContent}>Criar Curso</DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={getContents}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '10px',
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Digite sua pesquisa aqui..."
            value={contentName}
            onChange={e => setContentName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton
            className="small"
            style={{ margin: 0, height: '43px' }}
            type="submit"
          >
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Duração</span>,
            propName: 'duration',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
            order: (_: any[], currentOrder: string) => {
              setContents([
                ...contents.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes('active') &&
                    currentOrder.includes('asc');
                  if (isDescendant) {
                    return a.active ? 1 : -1;
                  } else {
                    return a.active ? -1 : 1;
                  }
                }),
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados cursos cadastrados!'}
      />
    </ContentsContainer>
  );
};

export default Contents;
