import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../../components/DefaultButton";
import { DefaultPageTitle } from "../../../components/DefaultPageTitle";
import DefaultTable from "../../../components/DefaultTable";

import {
  getAllCategories,
  deleteCategory,
} from "../../../services/forum-category";
import { CategoriesContainer } from "./style";
import Category from "../../../models/category";
import DefaultFilterForm from "../../../components/DefaultFilterForm";
import DefaultInput from "../../../components/DefaultInput";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";

const Categories: React.FC = () => {
  const history = useHistory();

  const [categories, setCategories] = useState([] as Category[]);
  const [categoryName, setCategoryName] = useState('');

  const getCategories = async (event?: React.FormEvent) => {    
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const localCategories = await getAllCategories({ description: categoryName });
    setCategories(localCategories);
  };

  const editCategory = useCallback(
    (categoryId: any) => {
      history.push(`edit-category/${categoryId}`);
    },
    [history]
  );

  const removeCategory = useCallback(async (categoryId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover esta categoria?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCategory(categoryId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Categoria excluida com sucesso!",
          });

          await getCategories();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: `Erro ao excluir categoria. ${
              error.response && error.response.status === 423
                ? "Esta categoria já está associada a um tópico!"
                : error.message
            }`,
          });
        }
      }
    });
  }, []);

  const createCategory = async () => {
    history.push("create-category");
  };

  const contentsToBeShown = useMemo(() => {
    return categories && categories.length
      ? categories.map((category) => ({
          id: category.category_id,
          title: category.description,
          questions: (
            <div className="button">
              <span>
                {category.questions && category.questions > 0 ? (
                  <DefaultButton
                    style={{ margin: 0 }}
                    className="questions-number transparent small"
                    onClick={() =>
                      history.push(
                        `/forum/categories/${category.category_id}/questions`
                      )
                    }
                  >
                    <span>{category.questions}</span>{' '}
                    <AiOutlineEye size={18} />
                  </DefaultButton>
                ) : null}
              </span>
            </div>
          ),
          actions: (
            <>
              <DefaultButton
                onClick={() => editCategory(category.category_id)}
                className="small info"
                title="Editar Categoria"
              >
                <BiEdit />
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() => {
                  removeCategory(category.category_id);
                }}
                className="small danger"
                title="Excluir Categoria"
              >
                <BiTrash />
              </DefaultButton>
            </>
          ),
        }))
      : [];
  }, [categories, editCategory, removeCategory]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <CategoriesContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Fórum</span>,
          <span>Categorias</span>,
        ]}
      />

      <DefaultPageTitle>Categorias</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createCategory}>Criar Categoria</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultFilterForm onSubmit={getCategories} style={{ display: 'grid', gridTemplateColumns: '85% 15%', gap: '10px', margin: '10px 0' }}>
        <DefaultInput 
          type="search"
          placeholder="Pesquisar..." 
          value={categoryName} 
          onChange={e => setCategoryName(e.target.value)} 
          style={{ margin: 0 }}
        />
        <DefaultButton style={{ margin: 0 }} type="submit"><AiOutlineSearch size={18} /></DefaultButton>
      </DefaultFilterForm>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: "title",            
            order: (
              localUsers: any[],
              currentOrder: string
            ) => {
              setCategories([
                ...categories.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes("title") &&
                    currentOrder.includes("asc");
                  if (isDescendant) {
                    return a.title < b.title ? 1 : -1;
                  } else {
                    return a.title < b.title ? -1 : 1;
                  }
                })
              ]);
            },
          },
          {
            headerLabel: <span>Tópicos</span>,
            propName: "questions",
            attributes: { style: { textAlign: 'left' } },
            order: (
              localUsers: any[],
              currentOrder: string
            ) => {
              setCategories([
                ...categories.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes("questions") &&
                    currentOrder.includes("asc");
                  if (isDescendant) {
                    return (a.questions || 0) < (b.questions || 0) ? 1 : -1;
                  } else {
                    return (a.questions || 0) < (b.questions || 0) ? -1 : 1;
                  }
                })
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={"Não foram encontrados categorias cadastradas!"}
      />
    </CategoriesContainer>
  );
};

export default Categories;
