import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { BannerContainer } from './style';
import DefaultTable from '../../components/DefaultTable';
import Switch from 'react-switch';
import { getAllCourses, handleRecommended } from '../../services/trails';

const Banners: React.FC = () => {
  const [Banners, setBanners] = useState([] as any[]);

  const getSegmentation = async () => {
    const filters = {};
    const localBanner = await getAllCourses(filters);
    if (localBanner && localBanner.length) {
      setBanners(localBanner || []);
    }
  };

  const handleUpdateBanner = useCallback(async (banner: any) => {
    try {
      if (banner.trail_id) {
        const bool = banner.info.is_highlighted ? false : true;
        const filter = {...banner.info, is_highlighted: bool};
        await handleRecommended(banner.trail_id, filter);
      }

      Swal.fire({
        title: 'Sucesso',
        text: 'Exibição do curso alterado com sucesso!',
        icon: 'success',
      });

      await getSegmentation();
    } catch (err) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao alterar a exibição do curso.',
        icon: 'error',
      });
    }
  }, []);

  useEffect(() => {
    getSegmentation();
  }, []);

  const depositionsToBeShown = useMemo(() => {
    let localDepositions = [];
    if (Banners && Banners.length) {
      localDepositions.push(
        ...Banners.map((banner: any) => {
          return {
            title: banner.name,
            description: banner.description,
            show: (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '5px',
                }}
                title={
                  banner.info.is_highlighted
                    ? 'Esconder banner'
                    : 'Mostrar banner'
                }
              >
                <Switch
                  onChange={() => handleUpdateBanner(banner)}
                  checked={banner.info.is_highlighted}
                />
              </div>
            ),
          };
        }),
      );
    }

    return localDepositions;
  }, [Banners]);

  return (
    <BannerContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Banner</span>,
        ]}
      />

      <DefaultPageTitle>Banner</DefaultPageTitle>

      <div>
        <DefaultTable
          items={depositionsToBeShown}
          headersConfig={[
            {
              headerLabel: <span>Autor</span>,
              propName: 'title',
              attributes: { style: { width: '35%' } },
            },
            {
              headerLabel: <span>Descrição</span>,
              propName: 'description',
              attributes: {
                style: {
                  width: '35%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '300px',
                },
              },
            },
            {
              headerLabel: <span>Exibir</span>,
              propName: 'show',
            },
          ]}
        />
      </div>
    </BannerContainer>
  );
};

export default Banners;
