import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import CutImage from "../../components/CutImage";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import { hideModal, showModal } from "../../helpers/modal";
import {
  ThumbnailUploadContainer,
  EventThumbnail,
  CreateAndEditEventContainer,
} from "./style";
import {
  getEvent as getEventService,
  updateEvent as updateEventService,
  createEvent as createEventService,
  changeEventThumbnail as changeEventThumbnailService,
  deleteEvent,
} from "../../services/events";
import Swal from "sweetalert2";
import checkEmptyString from "../../helpers/check-empty-string";
import { EventLocal } from "../../models/from-api-response/event";
import Select from "react-select";
import brazillianCities from "../../storage/cidades-brasileiras.json";
import brazillianStates from "../../storage/estados-brasileiros.json";
import DatePicker from "react-datepicker";
import addDays from "../../helpers/add-days";
import addHours from "../../helpers/add-hours";

interface CreateAndEditEventProps {
  eventId: string;
}

const CreateAndEditEvent: React.FC = () => {
  const history = useHistory();
  const { eventId } = useParams<CreateAndEditEventProps>();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(addHours(new Date(), 1));
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  const [address, setAddress] = useState("");
  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [observation, setObservation] = useState("");
  const [price, setPrice] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();

  const createEvent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um nome valido para o evento.");
      }

      if (checkEmptyString(description)) {
        throw new Error("Informe uma descrição valida para o evento.");
      }

      if (!thumbnail && !thumbnailFileToUpload) {
        throw new Error("Informe uma imagem de capa para o evento.");
      }

      if (checkEmptyString(address)) {
        throw new Error("Informe um endereço para o evento.");
      }

      if (checkEmptyString(state)) {
        throw new Error("Informe um estado para o evento.");
      }

      if (checkEmptyString(city)) {
        throw new Error("Informe uma cidade para o evento.");
      }

      if (!startDate) {
        throw new Error("Informe uma data de inicio para o evento.");
      }

      if (startDate >= endDate) {
        throw new Error("Informe uma data de inicio inferior a data de finalização.");
      }

      if (startDate <= (new Date())) {
        throw new Error("Informe uma data de inicio superior a data atual.");
      }

      if (!endDate) {
        throw new Error("Informe uma data de finalização para o evento.");
      }

      if (endDate <= startDate) {
        throw new Error("Informe uma data de finalização superior a data de inicio.");
      }

      const createdEvent = await createEventService({
        title: title,
        description: description,
        start_date: startDate ? startDate.toISOString() : "",
        end_date: endDate ? endDate.toISOString() : "",
        local: actualLocal,
        observation: observation,
        price: price || "0",
      });

      if (thumbnailFileToUpload) {
        try {
          await changeEventThumbnail(
            thumbnailFileToUpload,
            createdEvent.event_id
          );
        } catch (error) {
          await deleteEvent(createdEvent.event_id);

          throw new Error(
            "Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Evento criado com sucesso!",
        icon: "success",
      });

      goToEvents();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar a evento. " + error.message,
        icon: "error",
      });
    }
  };

  const updateEvent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um nome valido para o evento.");
      }

      if (checkEmptyString(description)) {
        throw new Error("Informe uma descrição valida para o evento.");
      }

      if (!thumbnail && !thumbnailFileToUpload) {
        throw new Error("Informe uma imagem de capa para o evento.");
      }

      if (checkEmptyString(address)) {
        throw new Error("Informe um endereço para o evento.");
      }

      if (checkEmptyString(state)) {
        throw new Error("Informe um estado para o evento.");
      }

      if (checkEmptyString(city)) {
        throw new Error("Informe uma cidade para o evento.");
      }

      if (!startDate) {
        throw new Error("Informe uma data de inicio para o evento.");
      }

      if (startDate >= endDate) {
        throw new Error("Informe uma data de inicio inferior a data de finalização.");
      }

      if (startDate <= (new Date())) {
        throw new Error("Informe uma data de inicio superior a data atual.");
      }

      if (!endDate) {
        throw new Error("Informe uma data de finalização para o evento.");
      }

      if (endDate <= startDate) {
        throw new Error("Informe uma data de finalização superior a data de inicio.");
      }

      if (thumbnailFileToUpload) {
        try {
          await changeEventThumbnail(thumbnailFileToUpload, eventId);
        } catch (error) {
          throw new Error(
            "Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB."
          );
        }
      }

      await updateEventService(eventId, {
        title: title,
        description: description,
        start_date: startDate ? startDate.toISOString() : "",
        end_date: endDate ? endDate.toISOString() : "",
        local: actualLocal,
        observation: observation,
        price: price || "0",
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Evento editado com sucesso!",
        icon: "success",
      });

      goToEvents();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar a evento. " + error.message,
        icon: "error",
      });
    }
  };

  const changeEventThumbnail = async (
    localThumbnailFileToUpload: File,
    localEventId: string
  ) => {
    const formData = new FormData();
    formData.append("file", localThumbnailFileToUpload);
    formData.append(
      "name",
      `event_thumbnail_${localEventId}.${localThumbnailFileToUpload.type}`
    );
    formData.append("description", `thumbnail do evento de id ${localEventId}`);

    await changeEventThumbnailService(localEventId, formData);
  };

  const selectThumbnail = () => {
    showModal(
      "Selecionar Imagem de Capa",
      <CutImage aspect={1.812} onCutImage={onCutImage} />
    );
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const getEvent = useCallback(async () => {
    if (eventId) {
      const event = await getEventService(eventId);
      if (event && Object.keys(event).length) {
        setTitle(event.title);
        setDescription(event.description);
        setThumbnail(event.thumbnail);
        setAddress(event.address.street);
        setPlace(event.address.place);
        setState(event.address.state);
        setCity(event.address.city);
        setPrice(`${event.price ? event.price.replace("$", "") : ""}`);
        setStartDate(event.startDate);
        setEndDate(event.endDate);
        setObservation(event.observation);
      }
    }
  }, [eventId]);

  const goToEvents = () => {
    history.push("/events");
  };

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  const isEditting = useMemo(() => {
    if (eventId) {
      return true;
    }

    return false;
  }, [eventId]);

  const actualLocal = useMemo((): EventLocal => {
    return {
      street: address,
      place: place,
      city: city,
      state: state,
    };
  }, [address, place, city, state]);

  const citiesToShow = useMemo(() => {
    if (state) {
      return brazillianCities.filter((c) => c.state === state);
    }

    return [];
  }, [state]);

  const selectedState = useMemo(() => {
    return brazillianStates.find((s) => s.value === state);
  }, [state]);

  const selectedCity = useMemo(() => {
    return brazillianCities.find((c) => c.value === city);
  }, [city]);

  return (
    <CreateAndEditEventContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/home">Home</Link>,
          <Link to="/events">Cursos</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Evento</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Evento
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="description">
            Descrição
          </label>
          <DefaultTextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Capa
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <EventThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="address">
            Endereço
          </label>
          <DefaultInput
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            id="address"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="place">Local</label>
          <DefaultInput
            value={place}
            onChange={(e) => setPlace(e.target.value)}
            id="place"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="state">
            Estado
          </label>

          <Select
            value={selectedState}
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: "100%",
              }),
            }}
            options={brazillianStates}
            onChange={(option) => option && setState(option.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="city">
            Cidade
          </label>

          <Select
            value={selectedCity}
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: "100%",
              }),
            }}
            options={citiesToShow}
            onChange={(option) => option && setCity(option.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="price">Valor</label>
          <DefaultInput
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            id="price"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="startDate">
            Inicio
          </label>

          <DatePicker
            showTimeSelect
            selected={startDate}
            timeIntervals={5}
            onChange={(date) =>
              date && date instanceof Date && setStartDate(date)
            }
            onKeyDown={event => event.preventDefault()}
            maxDate={endDate}
            minDate={addHours(new Date(), 1)}
            dateFormat="dd/MM/yyyy hh:mm"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="endDate">
            Fim
          </label>
          <DatePicker
            showTimeSelect
            selected={endDate}
            timeIntervals={5}
            onChange={(date) =>
              date && date instanceof Date && setEndDate(date)
            }
            onKeyDown={event => event.preventDefault()}
            minDate={startDate}
            dateFormat="dd/MM/yyyy hh:mm"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="observation">Observação</label>
          <DefaultTextArea
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
            id="observation"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToEvents}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateEvent(e) : createEvent(e))}
            className="info"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditEventContainer>
  );
};

export default CreateAndEditEvent;
