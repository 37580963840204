import styled from 'styled-components';
import DefaultInput from '../../components/DefaultInput';

const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled.form`
  width: 500px;
  padding: 30px;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 140px;
    margin-bottom: 24px;
  }

  h1 {
    color: var(--default-title-color);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  button {
    width: 100%;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 0.2;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

const LoginFormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
`;

const InputLogin = styled(DefaultInput)`
  background-color: #e1e3e6;
  color: #000;
`;

const ContainerIcon = styled.div`
  position: absolute;
  top: 42px;
  right: 10px;
  z-index: 10;
  border: none;
  background: transparent;
  outline: none;
`;

export { LoginContainer, LoginForm, LoginFormGroup, InputLogin, ContainerIcon };
