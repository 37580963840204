import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../../../../components/BreadCrumb";
import DefaultButton from "../../../../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../../../../components/DefaultCreationForm";
import DefaultInput from "../../../../../components/DefaultInput";
import { DefaultPageTitle } from "../../../../../components/DefaultPageTitle";
import { CreateAndEditCategoryContainer } from "./style";
import checkEmptyString from "../../../../../helpers/check-empty-string";
import {
  createCategory as createCategoryService,
  getCategory,
  updateCategory as updateCategoryService,
} from "../../../../../services/forum-category";

interface CreateAndEditCategoryProps {
  categoryId: string;
}

const CreateAndEditForumCategory: React.FC = () => {
  const history = useHistory();

  const { categoryId } = useParams<CreateAndEditCategoryProps>();

  const [title, setTitle] = useState("");

  const createCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para a categoria.");
      }

      const response = await createCategoryService({
        description: title,
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Categoria criado com sucesso!",
        icon: "success",
      });

      goToCategories();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar a categoria. " + error.message,
        icon: "error",
      });
    }
  };

  const updateCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para a categoria.");
      }

      await updateCategoryService(categoryId, {
        description: title,
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Categoria editada com sucesso!",
        icon: "success",
      });

      goToCategories();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar a categoria. " + error.message,
        icon: "error",
      });
    }
  };

  const goToCategories = () => {
    history.push("/forum/categories");
  };

  const getContent = useCallback(async () => {
    if (categoryId) {
      const category = await getCategory(categoryId);
      if (category && Object.keys(category).length) {
        setTitle(category.description);
      }
    }
  }, [categoryId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (categoryId) {
      return true;
    }

    return false;
  }, [categoryId]);

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/categories">Categorias</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Categorias</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Categorias
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToCategories}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) =>
              isEditting ? updateCategory(e) : createCategory(e)
            }
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default CreateAndEditForumCategory;
