import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultCreateButtonAndSearchFormContainer } from '../../components/DefaultCreateButtonAndSearchFormContainer';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import useDebounce from '../../hooks/useDebounce';
import {
  deleteAcceptanceTerm,
  getAllAcceptanceTerm,
} from '../../services/acceptance-term';
import { TrailContainer } from '../Tags/style';

export interface AcceptanceTerm {
  id: string;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
}

export default function Terms() {
  const history = useHistory();

  const createTerm = async () => {
    history.push('create-term');
  };

  const [terms, setTerms] = useState<AcceptanceTerm[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce(searchTerm, 300);

  const handleGetAllAcceptanceTerms = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const response = await getAllAcceptanceTerm('compliance');
    setTerms(response);
  };

  useEffect(() => {
    handleGetAllAcceptanceTerms();
  }, []);

  const handleEditAcceptanceTerm = useCallback(
    (termId: any) => {
      history.push(`/edit-term/${termId}`);
    },
    [history],
  );

  const handleRemoveAcceptanceTerm = useCallback(async (termId: any) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este termo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteAcceptanceTerm(termId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Termo excluido com sucesso!',
          });

          await handleGetAllAcceptanceTerms();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir termo.`,
          });
        }
      }
    });
  }, []);

  const filteredTerms = terms.filter(t =>
    t.title
      .toLowerCase()
      .trim()
      .includes(debouncedSearch as string),
  );

  const contentsToBeShown = useMemo(() => {
    return filteredTerms && filteredTerms.length
      ? filteredTerms.map(term => ({
          id: term.id,
          title: term.title,
          description: term.description,
          created_at: new Date(term.created_at).toLocaleDateString('pt-BR'),
          updated_at: new Date(term.updated_at).toLocaleDateString('pt-BR'),
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() => handleEditAcceptanceTerm(term.id)}
                className="small info"
                title="Editar trilha"
              >
                <BiEdit />
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => handleRemoveAcceptanceTerm(term.id)}
                className="small danger"
                title="Excluir Trilha"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [
    filteredTerms,
    terms,
    handleRemoveAcceptanceTerm,
    handleEditAcceptanceTerm,
  ]);

  return (
    <TrailContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Dashboard</Link>, <span>Termos</span>]}
      />

      <DefaultPageTitle>Termos</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createTerm}>Criar Termos</DefaultButton>
        </CreateButtonDefaultContainer>

        <DefaultInput
          type="search"
          placeholder="Digite sua pesquisa aqui..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          style={{ width: '280px', margin: 0 }}
        />
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Data de criação</span>,
            propName: 'created_at',
          },
          {
            headerLabel: <span>Data de atualização</span>,
            propName: 'updated_at',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados termos cadastrados!'}
      />
    </TrailContainer>
  );
}
