import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../../../../components/BreadCrumb";
import DefaultButton from "../../../../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../../../../components/DefaultCreationForm";
import DefaultInput from "../../../../../components/DefaultInput";
import { DefaultPageTitle } from "../../../../../components/DefaultPageTitle";
import { CreateAndEditCategoryContainer } from "./style";
import checkEmptyString from "../../../../../helpers/check-empty-string";
import {
  createTag as createTagService,
  getTag,
  updateTag as updateTagService,
} from "../../../../../services/tags";

interface CreateAndEditCategoryProps {
  tagId: string;
}

const CreateAndEditForumTag: React.FC = () => {
  const history = useHistory();

  const { tagId } = useParams<CreateAndEditCategoryProps>();

  const [title, setTitle] = useState("");

  const createCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para a tag.");
      }

      await createTagService({
        description: title,
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Tag criada com sucesso!",
        icon: "success",
      });

      goToCategories();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar o tag. " + error.message,
        icon: "error",
      });
    }
  };

  const updateCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para a tag.");
      }

      await updateTagService(tagId, {
        description: title,
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Tag editada com sucesso!",
        icon: "success",
      });

      goToCategories();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao editar a tag. " + error.message,
        icon: "error",
      });
    }
  };

  const goToCategories = () => {
    history.push("/forum/tags");
  };

  const getContent = useCallback(async () => {
    if (tagId) {
      const category = await getTag(tagId);
      if (category && Object.keys(category).length) {
        setTitle(category.description);
      }
    }
  }, [tagId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (tagId) {
      return true;
    }

    return false;
  }, [tagId]);

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/categories">Tags</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Tag</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Tags
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToCategories}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) =>
              isEditting ? updateCategory(e) : createCategory(e)
            }
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default CreateAndEditForumTag;
