import styled from "styled-components";

const CreateAndEditEventContainer = styled.div``;

const ThumbnailUploadContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  img {
    margin-top: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EventThumbnail = styled.img`
  max-width: 600px;
`;

export {
  CreateAndEditEventContainer,
  EventThumbnail,
  ThumbnailUploadContainer
};
