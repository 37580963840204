import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { RecommendedContainer } from './style';
import DefaultTable from '../../components/DefaultTable';
import Switch from 'react-switch';
import { getAllCourses, handleRecommended } from '../../services/trails';

const Recommended: React.FC = () => {
  const [recommended, setRecommended] = useState([] as any[]);

  const getSegmentation = async () => {
    const filters = {};
    const localRecommended = await getAllCourses(filters);
    if (localRecommended && localRecommended.length) {
      setRecommended(localRecommended || []);
    }
  };

  const handleUpdateRecommended = useCallback(async (recommended: any) => {
    try {
      if (recommended.trail_id) {
        const bool = recommended.info.is_recommended ? false : true;
        const filter = {...recommended.info, is_recommended: bool};
        await handleRecommended(recommended.trail_id, filter);
      }

      Swal.fire({
        title: 'Sucesso',
        text: 'Exibição do curso alterado com sucesso!',
        icon: 'success',
      });

      await getSegmentation();
    } catch (err) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao alterar a exibição do curso.',
        icon: 'error',
      });
    }
  }, []);

  useEffect(() => {
    getSegmentation();
  }, []);

  const recommendedToBeShown = useMemo(() => {
    let localDepositions = [];
    if (recommended && recommended.length) {
      localDepositions.push(
        ...recommended.map((recommended: any) => {
          return {
            title: recommended.name,
            description: recommended.description,
            show: (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '5px',
                }}
                title={
                  recommended.show_to_all_users
                    ? 'Esconder dos usuários'
                    : 'Mostrar aos usuários'
                }
              >
                <Switch
                  onChange={() => handleUpdateRecommended(recommended)}
                  checked={recommended.info.is_recommended}
                />
              </div>
            ),
          };
        }),
      );
    }

    return localDepositions;
  }, [recommended]);

  return (
    <RecommendedContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Recomendados</span>,
        ]}
      />

      <DefaultPageTitle>Recomendados</DefaultPageTitle>

      <div>
        <DefaultTable
          items={recommendedToBeShown}
          headersConfig={[
            {
              headerLabel: <span>Autor</span>,
              propName: 'title',
              attributes: { style: { width: '35%' } },
            },
            {
              headerLabel: <span>Descrição</span>,
              propName: 'description',
              attributes: {
                style: {
                  width: '35%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '300px',
                },
              },
            },
            {
              headerLabel: <span>Exibir</span>,
              propName: 'show',
            },
          ]}
        />
      </div>
    </RecommendedContainer>
  );
};

export default Recommended;
