import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DepositionsContainer } from './style';
import { getQuestionsForCategory } from '../../services/forum-category';
import {
  changeContentDeposition,
  changeTrailDeposition,
  getAllDepositions,
} from '../../services/depositions';
import DefaultTable from '../../components/DefaultTable';
import Switch from 'react-switch';

const Depositions: React.FC = () => {
  const [depositions, setDepositions] = useState([] as any[]);

  const getSegmentation = async () => {
    const filters = {};
    const localDepositions = await getAllDepositions(filters);

    if (localDepositions && localDepositions.length) {
      setDepositions(localDepositions || []);
    }
  };

  const handleUpdateDeposition = useCallback(async (deposition: any) => {
    try {
      if (deposition.content_id) {
        await changeContentDeposition(
          deposition.content_id,
          deposition.user_id,
        );
      } else if (deposition.trail_id) {
        await changeTrailDeposition(deposition.trail_id, deposition.user_id);
      }

      Swal.fire({
        title: 'Sucesso',
        text: 'Exibição do depoimento alterado com sucesso!',
        icon: 'success',
      });

      await getSegmentation();
    } catch (err) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao alterar a exibição do depoimento.',
        icon: 'error',
      });
    }
  }, []);

  useEffect(() => {
    getSegmentation();
  }, [getQuestionsForCategory]);

  const depositionsToBeShown = useMemo(() => {
    let localDepositions = [];
    if (depositions && depositions.length) {
      localDepositions.push(
        ...depositions.map((deposition: any) => {
          return {
            title: deposition.name,
            description: deposition.description,
            show: (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '5px',
                }}
                title={
                  deposition.show_to_all_users
                    ? 'Esconder dos usuários'
                    : 'Mostrar aos usuários'
                }
              >
                <Switch
                  onChange={() => handleUpdateDeposition(deposition)}
                  checked={deposition.show_to_all_users}
                />
              </div>
            ),
          };
        }),
      );
    }

    return localDepositions;
  }, [depositions]);

  return (
    <DepositionsContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <span>Depoimentos</span>,
        ]}
      />

      <DefaultPageTitle>Depoimentos</DefaultPageTitle>

      <div>
        <DefaultTable
          items={depositionsToBeShown}
          headersConfig={[
            {
              headerLabel: <span>Autor</span>,
              propName: 'title',
              attributes: { style: { width: '35%' } },
            },
            {
              headerLabel: <span>Depoimento</span>,
              propName: 'description',
              attributes: {
                style: {
                  width: '35%',
                  maxWidth: '350px',
                },
              },
            },
            {
              headerLabel: <span>Exibir</span>,
              propName: 'show',
            },
          ]}
        />
      </div>
    </DepositionsContainer>
  );
};

export default Depositions;
