const getErrorMessage = (error: any) => {
  if (error?.response?.data?.validation?.body?.message) {
		return error.response.data.validation.body.message;
	}

	if (error?.response?.data?.message) {
		return error.response.data.message;
	}

	return error.message;
};

export default getErrorMessage;
