import httpClient from '../http-client';

const getAllAcceptanceTerm = async (type?: string) => {
  console.log(type);
  
  const allAcceptanceTermResponse = (await httpClient.get<any[]>(
    type !== undefined? `terms?type=${type}` : 'terms'
  )).data;

  return allAcceptanceTermResponse;
};

const getAcceptanceTermById = async (acceptanceTermId: string) => {
  const acceptanceTermResponse = (
    await httpClient.get<any>(`terms/${acceptanceTermId}`)
  ).data;

  return acceptanceTermResponse;
};

interface acceptanceTermProps {
  title: string;
  description: string;
  type: string;
  reference?: string | '';
  filename?: string | '';
}

const createAcceptanceTerm = async (content: acceptanceTermProps) => {
  const createAcceptanceTermResponse = (
    await httpClient.post<any>(`terms`, content)
  ).data;
  
  return createAcceptanceTermResponse;
};

const updateAcceptanceTerm = async (
  acceptanceTermId: string,
  content: acceptanceTermProps,
) => {
  await httpClient.put(`terms/?termId=${acceptanceTermId}`, content);
};

const deleteAcceptanceTerm = async (acceptanceTermId: string) => {
  await httpClient.delete(`terms/?termId=${acceptanceTermId}`);
};

export {
  getAcceptanceTermById,
  getAllAcceptanceTerm,
  createAcceptanceTerm,
  updateAcceptanceTerm,
  deleteAcceptanceTerm,
};
