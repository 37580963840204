import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { CreateAndEditTrailContainer } from './style';
import checkEmptyString from '../../helpers/check-empty-string';
import {
  createTagCategory as createTagCategoryService,
  getTagCategory,
  updateTagCategory as updateTagCategoryService,
} from '../../services/tag-categories';

interface CreateAndEditTagCategoryProps {
  tagCategoryId: string;
}

const CreateAndEditCategory: React.FC = () => {
  const history = useHistory();

  const { tagCategoryId } = useParams<CreateAndEditTagCategoryProps>();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const createTagCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título valido para a categoria.');
      }

      await createTagCategoryService({
        title: title,
        description: description.trim().length ? description : undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Trilha criado com sucesso!',
        icon: 'success',
      });

      goToTagCategories();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o trilha. ' + error.message,
        icon: 'error',
      });
    }
  };

  const updateTagCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título valido para a trilha.');
      }

      await updateTagCategoryService(tagCategoryId, {
        title: title,
        description: description || undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'trilha editado com sucesso!',
        icon: 'success',
      });

      goToTagCategories();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o conteúdo. ' + error.message,
        icon: 'error',
      });
    }
  };

  const goToTagCategories = () => {
    history.push('/trails');
  };

  const getContent = useCallback(async () => {
    if (tagCategoryId) {
      const category = await getTagCategory(tagCategoryId);
      if (category && Object.keys(category).length) {
        setTitle(category.title);
        setDescription(category.description);
      }
    }
  }, [tagCategoryId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (tagCategoryId) {
      return true;
    }

    return false;
  }, [tagCategoryId]);

  return (
    <CreateAndEditTrailContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/trails">Trilhas</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Trilhas</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Trilhas
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={e => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description}
            onChange={e => setDescription(e.target.value)}
            id="description"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToTagCategories}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={e => (isEditting ? updateTagCategory(e) : createTagCategory(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditTrailContainer>
  );
};

export default CreateAndEditCategory;
