import React, { useEffect, useState } from "react";
import getActualUser from "../../helpers/get-actual-user";
import User from "../../models/user";
import { ProfileContainer } from "./style";

import logoImg from "../../assets/logo.svg"

const Profile: React.FC = () => {
  const [user, setUser] = useState({} as User);

  useEffect(() => {
    (async () => {
      setUser(await getActualUser());
    })();
  }, []);

  return (
    <ProfileContainer>
      <h1>Seja Bem Vindo(a), {user.name}</h1>
      <img src={logoImg} alt="Logomarca D'or Consultoria" />
    </ProfileContainer>
  );
};

export default Profile;
