import styled from 'styled-components';

const KeysContainer = styled.div`
  .noExpiration {
    color: rgba(0, 0, 0, 0.7);
  }
  .expiration {
    color: rgba(0, 0, 0, 0.3);
    text-decoration: line-through;
  }
`;

export { KeysContainer };
