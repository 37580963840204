import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import {
  getAllTagCategories,
  deleteTagCategory as deleteTagCategoryService,
  activateOrInactivateTagCategory as activateOrInactivateTagCategoryService,
} from '../../services/tag-categories';
import { TrailContainer } from './style';
import TagCategory from '../../models/tagCategory';
import DefaultInput from '../../components/DefaultInput';
import { DefaultCreateButtonAndSearchFormContainer } from '../../components/DefaultCreateButtonAndSearchFormContainer';

const TagCategories: React.FC = () => {
  const history = useHistory();

  const [tagCategories, setTagCategories] = useState([] as TagCategory[]);
  const [tagCategoryName, setTagCategoryName] = useState('');

  const getTagCategories = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const localTagCategories = await getAllTagCategories({
      title: tagCategoryName,
    });
    setTagCategories(localTagCategories || []);
  };

  const editTagCategory = useCallback(
    (tagCategoryId: any) => {
      history.push(`/edit-trail/${tagCategoryId}`);
    },
    [history],
  );

  const removeTagCategory = useCallback(async (tagCategoryId: any) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta categoria?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteTagCategoryService(tagCategoryId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'trilha excluida com sucesso!',
          });

          await getTagCategories();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir trilha. ${
              (error.response && error.response.status === 423) || 400
                ? 'Esta trilha já está associada a um curso!'
                : error.message
            }`,
          });
        }
      }
    });
  }, []);

  const createTagCategory = async () => {
    history.push('create-trail');
  };

  const activateOrInactivateTagCategory = useCallback(
    async (tagCategoryId: any, activate: boolean) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${
          activate ? 'ativar' : 'inativar'
        } esta trilha?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateTagCategoryService(tagCategoryId, activate);

            getTagCategories();
          } catch (error) {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao ${activate ? 'ativar' : 'inativar'} trilha. ${
                (error.response && error.response.status === 423) || 400
                  ? 'Esta trilha já está associada a um curso!'
                  : error.message
              }`,
            });
          }
        }
      });
    },
    [],
  );

  const contentsToBeShown = useMemo(() => {
    return tagCategories && tagCategories.length
      ? tagCategories.map(tagCategory => ({
          id: tagCategory.tag_category_id,
          title: tagCategory.title,
          description: tagCategory.description,
          active: tagCategory.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() => editTagCategory(tagCategory.tag_category_id)}
                className="small info"
                title="Editar trilha"
              >
                <BiEdit />
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() =>
                  activateOrInactivateTagCategory(
                    tagCategory?.tag_category_id,
                    !tagCategory.is_active,
                  )
                }
                className="small warning"
                title={
                  (tagCategory.is_active ? 'Inativar' : 'Ativar') + ' Trilha'
                }
              >
                {tagCategory.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => {
                  removeTagCategory(tagCategory.tag_category_id);
                }}
                className="small danger"
                title="Excluir Trilha"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [
    tagCategories,
    editTagCategory,
    removeTagCategory,
    activateOrInactivateTagCategory,
  ]);

  useEffect(() => {
    getTagCategories();
  }, []);

  useEffect(() => {
    if (!tagCategoryName) {
      getTagCategories();
    }
  }, [tagCategoryName]);

  return (
    <TrailContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Dashboard</Link>, <span>Trilhas</span>]}
      />

      <DefaultPageTitle>Trilhas</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createTagCategory}>Criar Trilhas</DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={getTagCategories}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '10px',
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Digite sua pesquisa aqui..."
            value={tagCategoryName}
            onChange={e => setTagCategoryName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton
            className="small"
            style={{ margin: 0, height: '43px' }}
            type="submit"
          >
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
            order: (_: any[], currentOrder: string) => {
              setTagCategories([
                ...tagCategories.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes('active') &&
                    currentOrder.includes('asc');
                  if (isDescendant) {
                    return a.is_active ? 1 : -1;
                  } else {
                    return a.is_active ? -1 : 1;
                  }
                }),
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados categorias cadastradas!'}
      />
    </TrailContainer>
  );
};

export default TagCategories;
