import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import Event from "../../models/event";
import { EventLocal } from "../../models/from-api-response/event";
import {
  deleteEvent,
  getAllEvents,
  activateOrInactivateEvent as activateOrInactivateEventService,
} from "../../services/events";

const Events: React.FC = () => {
  const history = useHistory();

  const [events, setEvents] = useState([] as Event[]);

  const getEvents = async () => {
    const localEvents = await getAllEvents();
    if (localEvents && localEvents.length) {
      setEvents(localEvents);
    }
  };

  const convertToAddressString = (local: EventLocal) => {
    let address = "";

    if (local.street) {
      address += `End.: ${local.street}`;
    }

    if (local.place) {
      address += `, Local: ${local.place}`;
    }

    if (local.city) {
      address += `, Cidade: ${local.city}`;
    }

    if (local.state) {
      address += `, UF: ${local.state}`;
    }

    return address;
  };

  const editEvent = useCallback(
    (eventId: string) => {
      history.push(`edit-event/${eventId}`);
    },
    [history]
  );

  const removeEvent = useCallback(async (eventId: string) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este evento?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteEvent(eventId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Evento excluido com sucesso!",
          });

          await getEvents();
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Erro ao excluir evento",
            text: e.message,
          });
        }
      }
    });
  }, []);

  const createEvent = async () => {
    history.push("create-event");
  };

  const convertToDateString = (date: Date) => {
    return new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);
  };

  const activateOrInactivateEvent = useCallback(
    async (eventId: string, activate: boolean) => {
      Swal.fire({
        title: "Confirmação",
        text: `Tem certeza que deseja ${
          activate ? "ativar" : "inativar"
        } este evento?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateEventService(eventId, activate);

            getEvents();
          } catch (error) {
            Swal.fire({
              title: "Erro",
              text: `Erro ao ${activate ? "ativar" : "inativar"} evento!`,
            });
          }
        }
      });
    },
    []
  );

  const eventsToBeShown = useMemo(() => {
    return events && events.length
      ? events.map((event) => ({
          title: event.title,
          description: event.description,
          startDate: convertToDateString(event.startDate),
          endDate: convertToDateString(event.endDate),
          address: convertToAddressString(event.address),
          active: event.active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <>
              <DefaultButton
                onClick={() => editEvent(event.id)}
                className="small info"
              >
                <BiEdit />
              </DefaultButton>{" "}
              <DefaultButton
                className="small warning"
                title={(event.active ? "Inativar" : "Ativar") + " Conteúdo"}
                onClick={() =>
                  activateOrInactivateEvent(event.id, !event.active)
                }
              >
                {event.active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() => {
                  removeEvent(event.id);
                }}
                className="small danger"
                title="Excluir Curso"
              >
                <BiTrash />
              </DefaultButton>
            </>
          ),
        }))
      : [];
  }, [events, editEvent, removeEvent, activateOrInactivateEvent]);

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div>
      <BreadCrumb
        crumbs={[<Link to="/home">Home</Link>, <span>Eventos</span>]}
      />

      <DefaultPageTitle>Eventos</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createEvent}>Criar Evento</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: "title",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Inicio</span>,
            propName: "startDate",
          },
          {
            headerLabel: <span>Fim</span>,
            propName: "endDate",
          },
          {
            headerLabel: <span>Endereço</span>,
            propName: "address",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
            noWrap: true,
          },
        ]}
        items={eventsToBeShown}
        emptyListMessage={"Não foram encontrados eventos cadastrados!"}
      />
    </div>
  );
};

export default Events;
