import React, { useState } from "react";
import DefaultButton from "../../../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../../../components/DefaultCreationForm";
import { DefaultTextArea } from "../../../../components/DefaultTextArea";
import { hideModal } from "../../../../helpers/modal";
import { reproveArticle as reproveArticleService } from "../../../../services/articles";
import Swal from "sweetalert2";
import checkEmptyString from "../../../../helpers/check-empty-string";

interface ArticleFeedbackProps {
  articleId: string;
  onReprove: () => void;
}

const ArticleFeedback: React.FC<ArticleFeedbackProps> = ({
  articleId,
  onReprove,
}) => {
  const [feedback, setFeedback] = useState("");

  const reproveArticle = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(feedback)) {
        throw new Error(
          "Informe um motivo de reprovação válido para o artigo!"
        );
      }

      await reproveArticleService(articleId, feedback);

      Swal.fire({
        title: "Sucesso!",
        text: "Artigo reprovado com sucesso!",
        icon: "success",
      });

      onReprove();

      hideModal();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Erro ao reprovar artigo. " + error.message,
        icon: "error",
      });
    }
  };

  return (
    <div>
      <DefaultCreationForm onSubmit={reproveArticle}>
        <DefaultCreationFormGroup>
          <label htmlFor="motivo">Motivo da Reprovação</label>

          <DefaultTextArea
            onChange={(e) => setFeedback(e.target.value)}
            value={feedback}
            rows={6}
          ></DefaultTextArea>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton className="danger" type="button" onClick={hideModal}>
            Cancelar
          </DefaultButton>
          <DefaultButton className="info" type="submit">
            Confirmar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </div>
  );
};

export default ArticleFeedback;
