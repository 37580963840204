import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultInput from '../../components/DefaultInput';
import { generateAccessKey as generateAccessKeyService } from '../../services/register-key';

import { RegisterKeyContainer } from './style';

const Users: React.FC = () => {
  const history = useHistory();
  const [dateExpiration, setDateExpiration] = useState<string>('');
  const [amount, setAmount] = useState<number>(1);

  const generateAccessKey = async (event: React.FormEvent) => {
    event.preventDefault();

    if (dateExpiration.length === 0) {
      Swal.fire({
        title: 'Erro',
        text: 'Selecione uma data de expiração para a chave de acesso!',
        icon: 'error',
      });

      return;
    }

    if (new Date() > new Date(dateExpiration)) {
      Swal.fire({
        title: 'Erro',
        text: 'Data de expiração anterior ao dia atual!',
        icon: 'error',
      });

      return;
    }

    await generateAccessKeyService(dateExpiration, amount)
      .then(response => {
        Swal.fire({
          title: 'Sucesso!',
          text: `Chave gerada`,
          icon: 'success',
        });
        history.push('/access-key');
      })
      .catch(() => {
        Swal.fire({
          title: 'Erro',
          text: 'Erro ao gerar chave de acesso!',
          icon: 'error',
        });
      });
  };

  return (
    <RegisterKeyContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/access-key">Chave de acesso</Link>,
          <span>Chaves de Acesso</span>,
        ]}
      />
      <DefaultPageTitle>Chave de Acesso</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Data de Validade da Chave de Acesso
          </label>
          <DefaultInput
            type="date"
            value={dateExpiration}
            onChange={e => setDateExpiration(e.target.value)}
            id="expiration-date"
            required
          />
          <label className="amount">Quantidade de chaves</label>
          <DefaultInput
            type="number"
            value={amount}
            min={1}
            max={100}
            onChange={e => setAmount(parseInt(e.target.value))}
            id="Quantidade de Chaves de Acesso"
          />

          <DefaultButton className="success" onClick={generateAccessKey}>
            Gerar Chave de Acesso
          </DefaultButton>
        </DefaultCreationFormGroup>
      </DefaultCreationForm>
    </RegisterKeyContainer>
  );
};

export default Users;
