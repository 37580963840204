import User from '../models/user';
import { getActualUser as getActualUserService } from '../services/users';

const getActualUser = async (): Promise<User> => {
  let user = {} as User;
  if (!window.localStorage.getItem('actual-dorconsultoria-user')) {
    user = await getActualUserService();
    window.localStorage.setItem(
      'actual-dorconsultoria-user',
      JSON.stringify(user),
    );
  } else {
    user = JSON.parse(
      window.localStorage.getItem('actual-dorconsultoria-user') || '',
    );
  }

  return user;
};

export default getActualUser;
