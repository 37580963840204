import httpClient from '../http-client';
import download from 'downloadjs';

const generateEngagementReport = async (
  format: string,
  queryString: string,
) => {
  const reportResponse = await httpClient.get(
    `reports/engagement-course/${format}`,
    {
      params: queryString,
      responseType: 'blob',
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_engajamento_curso.${format}`,
    contentType,
  );
};

const generateFinishedCoursesReport = async (
  format: string,
  queryString: string,
) => {
  const reportResponse = await httpClient.get(
    `reports/courses-finished/${format}`,
    {
      params: queryString,
      responseType: 'blob',
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_cursos_finalizados.${format}`,
    contentType,
  );
};

const generateTermReport = async (
  format: string,
  queryString: string,
) => {
  const reportResponse = await httpClient.get(
    `terms/search/report/${format}`,
    {
      params: queryString,
      responseType: 'blob',
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_termo.${format}`,
    contentType,
  );
};

export { generateEngagementReport, generateFinishedCoursesReport, generateTermReport };
