import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import DefaultButton from '../../components/DefaultButton';
// import DefaultInput from "../../components/DefaultInput";

import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { signIn as signInService } from '../../services/signIn';

import Logo from '../../assets/logo.svg';
import {
  ContainerIcon,
  InputLogin,
  LoginContainer,
  LoginForm,
  LoginFormGroup,
} from './style';
import checkEmptyString from '../../helpers/check-empty-string';

const Login: React.FC = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('password');

  const history = useHistory();

  const handleShowPassword = () => {
    if (showPassword === 'password') {
      setShowPassword('text');
    } else {
      setShowPassword('password');
    }
  };

  const login = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!checkEmptyString(user) && !checkEmptyString(password)) {
      await signInService({ registration: user, password }).then(() => {
        history.push('/profile');
      });
    } else {
      Swal.fire({
        title: 'Erro',
        text: 'Preencha todos os campos.',
        icon: 'error',
      });
    }
  };

  return (
    <LoginContainer>
      <LoginForm onSubmit={login}>
        <img src={Logo} alt="D'or Consultoria" />
        <h1>Entrar</h1>
        <LoginFormGroup>
          <label htmlFor="login">Email</label>
          <InputLogin
            value={user}
            onChange={e => setUser(e.target.value)}
            required
            id="login"
            placeholder="Login"
          />
        </LoginFormGroup>
        <LoginFormGroup>
          <label htmlFor="senha">Senha</label>
          <InputLogin
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            type={showPassword}
            id="senha"
            placeholder="Senha"
          />
          <ContainerIcon>
            {showPassword === 'password' ? (
              <FaEyeSlash color="#909599" onClick={handleShowPassword} />
            ) : (
              <FaEye color="#909599" onClick={handleShowPassword} />
            )}
          </ContainerIcon>
        </LoginFormGroup>

        <DefaultButton type="submit">Entrar</DefaultButton>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;
