import httpClient from '../http-client';
import RegisterKey from '../models/register-key';

const generateAccessKey = async (
  expiration_time: string,
  amount: number,
): Promise<RegisterKey> => {
  const accessKey = (
    await httpClient.post('/keys-register', {
      expiration_time,
      amount
    })
  ).data;

  return accessKey;
};

const getAccessKeys = async () => {
  const getAccessKeys = (await httpClient.get('/keys-register/list')).data;
  return getAccessKeys;
};

export { generateAccessKey, getAccessKeys };
