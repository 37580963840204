import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../../../components/BreadCrumb";
import DefaultButton from "../../../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
} from "../../../../components/DefaultCreationForm";
import { BiTrash } from "react-icons/bi";
import { DefaultPageTitle } from "../../../../components/DefaultPageTitle";
import { CreateAndEditCategoryContainer, TrashButton } from "./style";
import Datatable from "../../../../components/Datatable";
import {
  deleteQuestions,
  getQuestionsForCategory,
} from "../../../../services/forum-category";

interface CreateAndEditCategoryProps {
  categoryId: string;
}

interface ItemsToBeAdded {
  id: string;
  value: string;
}

interface IQuestions {
  question_id: string;
  title: string;
  description: string;
}

interface Item {
  segmentation_item_id: string;
  item: any;
  questions: IQuestions[];
}

const EditQuestions = () => {
  const [itemsToBeChanged, setItemsToBeChanged] = useState(
    [] as ItemsToBeAdded[]
  );
  const [itemsToBeAdded, setItemsToBeAdded] = useState([] as ItemsToBeAdded[]);
  const [
    shouldShowSegmentationItemsTable,
    setShouldShowSegmentationItemsTable,
  ] = useState(false);
  const history = useHistory();

  const { categoryId } = useParams<CreateAndEditCategoryProps>();
  const [segmentationItems, setSegmentationItems] = useState([] as Item[]);

  const getSegmentation = async () => {
    const localQuestions = await getQuestionsForCategory(categoryId);
    setShouldShowSegmentationItemsTable(false);
    setSegmentationItems(localQuestions || []);
  };

  useEffect(() => {
    getSegmentation();
  }, [getQuestionsForCategory]);

  const goToSegmentations = () => {
    history.push("/forum/categories");
  };

  const handleChange = (
    segmentationDescription: string,
    segmentationItemId: string
  ) => {
    if (!itemsToBeChanged.some((item) => item.id === segmentationItemId)) {
      if (
        segmentationItems.some(
          (item) => item.segmentation_item_id === segmentationItemId
        )
      ) {
        itemsToBeChanged.push({
          id: segmentationItemId,
          value: segmentationDescription,
        });
      } else {
        const foundItemToCreate = itemsToBeAdded.find(
          (item) => item.id === segmentationItemId
        );
        if (foundItemToCreate) {
          const indexOfFoundItemToCreate = itemsToBeAdded.indexOf(
            foundItemToCreate
          );
          if (
            indexOfFoundItemToCreate > -1 &&
            indexOfFoundItemToCreate < itemsToBeAdded.length
          ) {
            itemsToBeAdded.splice(indexOfFoundItemToCreate, 1, {
              id: segmentationItemId,
              value: segmentationDescription,
            });
          }
        }

        setItemsToBeAdded([...itemsToBeAdded]);
      }
    } else {
      const foundItemToBeChanged = itemsToBeChanged.find(
        (item) => item.id === segmentationItemId
      );
      if (foundItemToBeChanged) {
        foundItemToBeChanged.value = segmentationDescription;
      }
    }

    setItemsToBeChanged([...itemsToBeChanged]);
  };

  const removeItem = useCallback(async (categoryId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este tópico?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteQuestions(categoryId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Tópico excluido com sucesso!",
          });

          await getSegmentation();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: `Erro ao excluir a tópico.`,
          });
        }
      }
    });
  }, []);

  const segmentationItemsToBeShown = useMemo(() => {
    let localSegmentationItems = [];
    if (segmentationItems && segmentationItems.length) {
      localSegmentationItems.push(
        ...segmentationItems[0].questions.map((segItem: any) => {
          return {
            title: (
              <textarea
                style={{ width: "100%" }}
                defaultValue={segItem.title}
                readOnly
              ></textarea>
            ),
            descrição: (
              <textarea
                readOnly
                style={{ width: "100%" }}
                defaultValue={segItem.description}
              ></textarea>
            ),
            ações: (
              <>
                <TrashButton onClick={() => removeItem(segItem.question_id)}>
                  <BiTrash size={18} />
                </TrashButton>
              </>
            ),
          };
        })
      );
    }

    if (itemsToBeAdded && itemsToBeAdded.length) {
      localSegmentationItems.push(
        ...itemsToBeAdded.map((segItem) => ({
          descrição: (
            <textarea
              style={{ width: "100%" }}
              defaultValue={segItem.value}
              onChange={(event) => handleChange(event.target.value, segItem.id)}
            ></textarea>
          ),
          ações: (
            <>
              <TrashButton onClick={() => removeItem(segItem.id)}>
                <BiTrash size={18} />
              </TrashButton>
            </>
          ),
        }))
      );
    }

    process.nextTick(() => {
      setShouldShowSegmentationItemsTable(true);
    });

    return localSegmentationItems;
  }, [segmentationItems, itemsToBeAdded]);

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/categories">Fórum</Link>,
          <span>Editar Tópicos</span>,
        ]}
      />

      <DefaultPageTitle>Editar Tópicos</DefaultPageTitle>

      <div>
        {shouldShowSegmentationItemsTable ? (
          <Datatable
            myData={segmentationItemsToBeShown}
            className=""
            pagination={false}
            pageSize={segmentationItemsToBeShown.length}
            customColumns={[
              {
                Header: "Titulo",
                accessor: "title",
              },
              {
                Header: "Descrição",
                accessor: "descrição",
              },
              {
                Header: "Ações",
                accessor: "ações",
                width: 150,
              },
            ]}
          />
        ) : (
          <></>
        )}
      </div>

      <DefaultCreationForm>
        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToSegmentations}
          >
            Cancelar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default EditQuestions;
