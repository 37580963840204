import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { ReportContainer } from './style';
import Select from 'react-select';
import DefaultButton from '../../components/DefaultButton';
import { generateTermReport } from '../../services/reports';
import { getAllAcceptanceTerm } from '../../services/acceptance-term';

export interface AcceptanceTerm {
  id: string;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
}

export interface ITerm {
  label: string,
  value: string,
}

const TermReport: React.FC = () => {
  const defautlValue = {
    label: '',
    value: '',
  }

  const [selectedConflict, setSelectedConflict] = useState<ITerm | null>(defautlValue);
  const [conflict, setConflict] = useState<ITerm[]>([]);

  const [terms, setTerms] = useState<ITerm[]>([]);
  const [selectedTerm, setSelectedTerm] = useState<ITerm | null>(defautlValue);

  const generateReport = async (reportType: string) => {
    const queryStrings: any = {
      term_id: selectedTerm?.value,
      has_conflict: selectedConflict?.value,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }
    
    await generateTermReport(reportType, queryStrings);
  };

  const getAllTerms = async () => {
    const localTerms = await getAllAcceptanceTerm('compliance');

    if (localTerms && localTerms.length) {   
      setTerms(
        localTerms.map((term: AcceptanceTerm) => ({
          label: term.title,
          value: term.id,
        }))
      );
    }

    setConflict([
      { value: 'true', label: 'Sim' },
      { value: 'false', label: 'Não' },
    ])
  };

  useEffect(() => {
    getAllTerms();
  }, []);
  
  return (
    <ReportContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/home">Home</Link>,
          <span>Relatórios</span>,
          <span>Relatório de Termo</span>,
        ]}
      />

      <DefaultPageTitle>Relatório de Termo</DefaultPageTitle>

      <DefaultFilterForm>
        <span className="entire-row">
          <label htmlFor="course">Termo</label>
          <Select
            placeholder="Selecione o termo"
            onChange={option => setSelectedTerm(option)
            }
            isClearable={true}
            options={terms}
          />
        </span>

        <span className="entire-row">
          <label htmlFor="course">Conflito</label>
          <Select
            placeholder="Selecione se tem conflito"
            onChange={option =>
              setSelectedConflict(option)
            }
            isClearable={true}
            options={conflict}
          />
        </span>

        <span className="entire-row center">
          <DefaultButton onClick={() => generateReport('pdf')} type="button">
            Gerar Relatório (PDF)
          </DefaultButton>{' '}
          <DefaultButton onClick={() => generateReport('csv')} type="button">
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </ReportContainer>
  );
};

export default TermReport;
