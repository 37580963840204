import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { CreateAndEditAuthorContainer } from './style';

import checkEmptyString from '../../helpers/check-empty-string';
import { createAuthor, getAuthor, updateAuthor } from '../../services/authors';

interface CreateAndEditContentProps {
  authorId: string;
}

const CreateAndEditContent: React.FC = () => {
  const { authorId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome valido para o autor.');
      }

      await createAuthor({
        name,
        description: description.trim().length ? description : undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Autor criado com sucesso!',
        icon: 'success',
      });

      goToAuthors();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o autor. ' + error.message,
        icon: 'error',
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome valido para o autor.');
      }

      await updateAuthor(authorId, {
        name,
        description: description || undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Autor editado com sucesso!',
        icon: 'success',
      });

      goToAuthors();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o autor. ' + error.message,
        icon: 'error',
      });
    }
  };

  const goToAuthors = () => {
    history.push('/authors');
  };

  const getAuthors = useCallback(async () => {
    if (authorId) {
      const author = await getAuthor(authorId);
      if (author && Object.keys(author).length) {
        setName(author.name);
        setDescription(author.description);
      }
    }
  }, [authorId]);

  useEffect(() => {
    getAuthors();
  }, [getAuthors]);

  const isEditting = useMemo(() => {
    if (authorId) {
      return true;
    }

    return false;
  }, [authorId]);

  return (
    <CreateAndEditAuthorContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Dashboard</Link>,
          <Link to="/authors">Autor</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Autor</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Autor
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Nome
          </label>
          <DefaultInput
            value={name}
            onChange={e => setName(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description}
            onChange={e => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToAuthors}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={e => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditAuthorContainer>
  );
};

export default CreateAndEditContent;
