import React from 'react';
import { Switch } from 'react-router-dom';
import Articles from '../../pages/Articles';
import Contents from '../../pages/Contents';
import CreateAndEditContent from '../../pages/CreateAndEditContent';
import Profile from '../../pages/Profile';
import Engagement from '../../pages/Engagement';
import FinishedCourses from '../../pages/FinishedCourses';
import Lessons from '../../pages/Lessons';
import Events from '../../pages/Events';
import CreateAndEditEvent from '../../pages/CreateAndEditEvent';
import AboutUs from '../../pages/AboutUs';
import Exams from '../../pages/Exams';
import Questions from '../../pages/Questions';
import CreateAndEditQuestion from '../../pages/CreateAndEditQuestion';
import CreateAndEditExam from '../../pages/CreateAndEditExam';
import Categories from '../../pages/Categories';
import ForumCategories from '../../pages/Forum/Categories';
import CreateAndEditCategory from '../../pages/CreateAndEditCategory';
import CreateAndEditForumCategory from '../../pages/Forum/Categories/components/CreateAndEditCategory';
import Authors from '../../pages/Authors';
import CreateAndEditAuthors from '../../pages/CreateAndEditAuthors';
import ContentTrail from '../../pages/ContentTrails';
import CreateAndEditContentTrails from '../../pages/CreateAndEditContentTrails';
import Series from '../../pages/Series';
import CreateAndEditSerie from '../../pages/CreateAndEditSerie';
import ForumTag from '../../pages/Forum/Tags';
import CreateAndEditForumTag from '../../pages/Forum/Tags/components/CreateAndEditTags';
import CreateAndEditFAQ from '../../pages/CreateAndEditFAQ';
import FAQ from '../../pages/FAQ';
import Segmentation from '../../pages/Segmentation';
import CreateAndEditSegmentation from '../../pages/CreateAndEditSegmentation';
import Lives from '../../pages/Lives';
import CreateAndEditLive from '../../pages/CreateAndEditLive';
import Users from '../../pages/Users';
import EditQuestions from '../../pages/Forum/Categories/EditQuestions';
import Depositions from '../../pages/Depositions';
import Recommended from '../../pages/Recommended';
import RegisterKeys from '../../pages/CreateRegisterKey';
import AccessKeys from '../../pages/RegisterKeys';
import Tags from '../../pages/Tags';
import CreateAndEditTags from '../../pages/CreateAndEditTags';
import Courses from '../../pages/Courses';
import CreateAndEditCourse from '../../pages/CreateAndEditCourse';
import Banners from '../../pages/Banner';
import Terms from '../../pages/Terms';
import CreateAndEditTerm from '../../pages/CreateAndEditTerm';

import { PrivateRoute as Route } from '../Auth';
import TermReport from '../../pages/TermReport';

const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/create-term" component={CreateAndEditTerm} />
        <Route exact path="/edit-term/:termId" component={CreateAndEditTerm} />
        
        <Route exact path="/responsibility-term" component={CreateAndEditTerm} />
        <Route exact path="/privacy-term" component={CreateAndEditTerm} />

        <Route exact path="/profile" component={Profile} />

        <Route exact path="/users" component={Users} />

        <Route exact path="/authors" component={Authors} />
        <Route exact path="/create-author" component={CreateAndEditAuthors} />
        <Route
          exact
          path="/edit-author/:authorId"
          component={CreateAndEditAuthors}
        />

        <Route exact path="/lives" component={Lives} />
        <Route exact path="/create-live" component={CreateAndEditLive} />
        <Route exact path="/edit-live/:liveId" component={CreateAndEditLive} />

        <Route exact path="/depositions" component={Depositions} />
        <Route exact path="/recommended" component={Recommended} />
        <Route exact path="/banner" component={Banners} />
        <Route exact path="/categories" component={Categories} />
        <Route
          exact
          path="/create-category"
          component={CreateAndEditCategory}
        />
        <Route
          exact
          path="/edit-category/:categoryId"
          component={CreateAndEditCategory}
        />
        <Route exact path="/trails" component={Tags} />
        <Route exact path="/create-trail" component={CreateAndEditTags} />
        <Route
          exact
          path="/edit-trail/:tagCategoryId"
          component={CreateAndEditTags}
        />

        <Route exact path="/contents" component={Contents} />
        <Route exact path="/create-content" component={CreateAndEditContent} />
        <Route
          exact
          path="/edit-content/:contentId"
          component={CreateAndEditContent}
        />

        <Route exact path="/course/episodes" component={Courses} />
        <Route
          exact
          path="/course/create-course"
          component={CreateAndEditCourse}
        />
        <Route
          exact
          path="/course/edit-course/:courseId"
          component={CreateAndEditCourse}
        />
        <Route exact path="/course/contents" component={ContentTrail} />

        <Route exact path="/trails/contents" component={ContentTrail} />
        <Route
          exact
          path="/course/create-content"
          component={CreateAndEditContentTrails}
        />

        <Route
          exact
          path="/course/edit-content/:contentId"
          component={CreateAndEditContentTrails}
        />

        <Route exact path="/course/series" component={Series} />
        <Route
          exact
          path="/course/create-serie"
          component={CreateAndEditSerie}
        />
        <Route
          exact
          path="/course/edit-serie/:serieId"
          component={CreateAndEditSerie}
        />

        <Route exact path="/faqs" component={FAQ} />
        <Route exact path="/create-faq" component={CreateAndEditFAQ} />
        <Route exact path="/edit-faq/:faqId" component={CreateAndEditFAQ} />

        <Route exact path="/forum/categories" component={ForumCategories} />
        <Route
          exact
          path="/forum/categories/:categoryId/questions"
          component={EditQuestions}
        />
        <Route
          exact
          path="/forum/create-category"
          component={CreateAndEditForumCategory}
        />
        <Route
          exact
          path="/forum/edit-category/:categoryId"
          component={CreateAndEditForumCategory}
        />

        <Route exact path="/forum/tags" component={ForumTag} />
        <Route
          exact
          path="/forum/create-tag"
          component={CreateAndEditForumTag}
        />
        <Route
          exact
          path="/forum/edit-tag/:tagId"
          component={CreateAndEditForumTag}
        />

        <Route exact path="/lessons" component={Lessons} />

        <Route exact path="/articles" component={Articles} />

        <Route exact path="/events" component={Events} />
        <Route exact path="/create-event" component={CreateAndEditEvent} />
        <Route
          exact
          path="/edit-event/:eventId"
          component={CreateAndEditEvent}
        />

        <Route exact path="/segmentation" component={Segmentation} />
        <Route
          exact
          path="/edit-segmentation/:segmentationId"
          component={CreateAndEditSegmentation}
        />

        <Route exact path="/reports/engagement" component={Engagement} />
        <Route exact path="/reports/terms" component={TermReport} />
        <Route
          exact
          path="/reports/finished-courses"
          component={FinishedCourses}
        />

        <Route exact path="/about/about_us" component={AboutUs} />

        <Route exact path="/exams-questions/exams" component={Exams} />
        <Route
          exact
          path="/exams-questions/create-exam"
          component={CreateAndEditExam}
        />
        <Route
          exact
          path="/exams-questions/edit-exam/:examId"
          component={CreateAndEditExam}
        />

        <Route exact path="/exams-questions/questions" component={Questions} />
        <Route
          exact
          path="/exams-questions/create-question"
          component={CreateAndEditQuestion}
        />
        <Route
          exact
          path="/exams-questions/edit-question/:questionId"
          component={CreateAndEditQuestion}
        />

        <Route exact path="/reports/engagement" component={Engagement} />
        <Route
          exact
          path="/reports/finishedCourses"
          component={FinishedCourses}
        />

        <Route exact path="/access-key" component={AccessKeys} />
        <Route exact path="/create-accessKey" component={RegisterKeys} />
      </Switch>
    </>
  );
};

export default Routes;
