import {
  AppContainer,
  AppContent,
  AppLeftContent,
  AppRightContent,
} from "./AppStyle";
import Routes from "./components/Routes";
import SideBar from "./components/SideBar";

function App() {
  return (
    <AppContainer>
      <AppLeftContent>
        <SideBar />
      </AppLeftContent>
      <AppRightContent>
        <AppContent>
          <Routes />
        </AppContent>
      </AppRightContent>
    </AppContainer>
  );
}

export default App;
