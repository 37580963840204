import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { CreateAndEditCategoryContainer } from './style';
import checkEmptyString from '../../helpers/check-empty-string';
import getErrorMessage from '../../helpers/get-error-message';
import {
  createAcceptanceTerm,
  getAcceptanceTermById,
  getAllAcceptanceTerm,
  updateAcceptanceTerm,
  deleteAcceptanceTerm,
} from '../../services/acceptance-term';
import { EditorState } from 'draft-js';
import {
  DescriptionTextarea,
  getCleanHtml,
} from './components/DescriptionTextarea';
import checkEmptyHtml from '../../helpers/check-empty-html';
import {
  ExtraMaterial,
  ExtraMaterialList,
  ExtraMaterialsInput,
} from '../CreateAndEditContent/style';
import { BiDownload, BiTrash } from 'react-icons/bi';
import { uploadFile } from '../../services/files';

interface CreateAndEditTermProps {
  termId: string;
}

const CreateAndEditTerm: React.FC = () => {
  const { termId } = useParams<CreateAndEditTermProps>();

  const history = useHistory();
  const inputRef = useRef<any>(null);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [id, setId] = useState('');
  const [extraTerm, setExtraTerm] = useState<any>({});
  const [termType, setTermType] = useState<string>('');
  const [existTerm, setExistTerm] = useState(false);

  const isResponsibilityTerm =
    history.location.pathname === '/responsibility-term';
  const isPrivacyTerm = history.location.pathname === '/privacy-term';

  const handleCreateAcceptanceTerm = async (event: React.FormEvent) => {
    event.preventDefault();

    const cleanHTML = getCleanHtml(editorState);

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o termo.');
      }

      const isEmpytString =
        isResponsibilityTerm || isPrivacyTerm
          ? checkEmptyHtml(cleanHTML)
          : checkEmptyString(description);

      if (isEmpytString) {
        throw new Error('Informe uma descrição válida para o termo.');
      }

      let referenceTerms =
        extraTerm.reference !== '' ? extraTerm.reference : '';
      let fileName = extraTerm.name !== '' ? extraTerm.name : '';

      if (!extraTerm.alreadySaved && Object.keys(extraTerm).length >= 1) {
        const formData = new FormData();
        formData.append('file', extraTerm);

        await uploadFile(formData).then(({ reference, name }) => {
          referenceTerms = reference;
          fileName = name;
        });
      }

      await createAcceptanceTerm({
        title: title,
        description:
          isResponsibilityTerm || isPrivacyTerm ? cleanHTML : description,
        type: termType,
        reference: referenceTerms,
        filename: fileName,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Termo criado com sucesso!',
        icon: 'success',
      });

      goToTerms();
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o termo. ' + errorMessage,
        icon: 'error',
      });
    }
  };

  const handleUpdateAcceptanceTerm = async (event: React.FormEvent) => {
    event.preventDefault();
    const cleanHTML = getCleanHtml(editorState);

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o termo.');
      }

      const isEmpytString =
        isResponsibilityTerm || isPrivacyTerm
          ? checkEmptyHtml(cleanHTML)
          : checkEmptyString(description);

      if (isEmpytString) {
        throw new Error('Informe uma descrição válida para o termo.');
      }

      let referenceTerms = Object.keys(extraTerm).length
        ? extraTerm.reference
        : '';
      let fileName = Object.keys(extraTerm).length ? extraTerm.name : '';

      if (!extraTerm.alreadySaved && Object.keys(extraTerm).length >= 1) {
        const formData = new FormData();
        formData.append('file', extraTerm);

        await uploadFile(formData).then(({ reference, name }) => {
          referenceTerms = reference;
          fileName = name;
        });
      }

      await updateAcceptanceTerm(id, {
        title: title,
        description:
          isResponsibilityTerm || isPrivacyTerm ? cleanHTML : description,
        type: termType,
        reference: referenceTerms ? referenceTerms : '',
        filename: fileName ? fileName : '',
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Termo editado com sucesso!',
        icon: 'success',
      });

      goToTerms();
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o termo. ' + errorMessage,
        icon: 'error',
      });
    }
  };

  const goToTerms = () => {
    isResponsibilityTerm || isPrivacyTerm
      ? history.push('/profile')
      : history.push('/terms');
  };

  const getContent = useCallback(async () => {
    if (id) {
      const content = await getAcceptanceTermById(id);
      if (content && Object.keys(content).length) {
        setTitle(content.title);
        setDescription(content.description);
      }
    }
  }, [termId]);

  const getResponsibilityContent = useCallback(async () => {
    const term = await getAllAcceptanceTerm('responsibility');

    let localExtraTerm = {};

    if (term.length) {
      setId(term[0].id);
      setTitle(term[0].title);
      setDescription(term[0].description);
    }

    if (term.length && term[0].filename !== '') {
      localExtraTerm = {
        name: term[0].filename,
        alreadySaved: true,
        reference: term[0].reference,
      };

      setExtraTerm(localExtraTerm);
    }
  }, []);

  const getPrivacyContent = useCallback(async () => {
    const term = await getAllAcceptanceTerm('privacy');

    let localExtraTerm = {};

    if (term.length) {
      setId(term[0].id);
      setTitle(term[0].title);
      setDescription(term[0].description);
    }

    if (term.length && term[0].filename !== '') {
      localExtraTerm = {
        name: term[0].filename,
        alreadySaved: true,
        reference: term[0].reference,
      };

      setExtraTerm(localExtraTerm);
    }
  }, []);
  const deleteTerm = useCallback(async (typeTerm: string) => {
    const term = await getAllAcceptanceTerm(typeTerm);
    if (term.length) {
      await deleteAcceptanceTerm(term[0].id);
      history.push('/profile');
    }
  }, []);
  const verifyTermExist = useCallback(async (typeTerm: string) => {
    const term = await getAllAcceptanceTerm(typeTerm);
    if (term.length) {
      setExistTerm(true);
    }
  }, []);
  useEffect(() => {
    setId(termId);
    if (isResponsibilityTerm) {
      setTermType('responsibility');
      verifyTermExist('responsibility');
      getResponsibilityContent();
    } else if (isPrivacyTerm) {
      setTermType('privacy');
      verifyTermExist('privacy');
      getPrivacyContent();
    } else {
      setTermType('compliance');
      getContent();
    }
  }, [getContent, isResponsibilityTerm, isPrivacyTerm, termId, termType]);

  const isEditting = useMemo(() => {
    if (id) {
      return true;
    }

    return false;
  }, [id]);

  const addTermInPdf = (event: any) => {
    event.preventDefault();

    if (event) {
      let file = event.target.files[0];
      file.alreadySaved = false;

      setExtraTerm(file);
    }

    inputRef.current.value = '';
  };

  const removeTermInPdf = () => {
    setExtraTerm({});
  };

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/terms">Termos</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Termos</span>,
        ]}
      />

      <DefaultPageTitle>
        <h2>{isEditting ? 'Editar' : 'Criar'} Termos</h2>
        <button
          style={existTerm === true ? { display: '' } : { display: 'none' }}
          type="button"
          className="danger"
          onClick={e => {
            Swal.fire({
              title: 'Confirmação',
              text: `Tem certeza que deseja deletar este termo?`,
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              focusConfirm: false,
            }).then(async result => {
              if (result.isConfirmed) {
                try {
                  if (history.location.pathname === '/responsibility-term') {
                    deleteTerm('responsibility');
                  } else if (history.location.pathname === '/privacy-term') {
                    deleteTerm('privacy');
                  }
                } catch (error) {
                  Swal.fire({
                    title: 'Erro',
                    text: `Erro ao deletar termo.`,
                  });
                }
              }
            });
            console.log(history.location);
          }}
        >
          Deletar termo
        </button>
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={e => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="description">
            Descrição
          </label>
          {isResponsibilityTerm || isPrivacyTerm ? (
            <DescriptionTextarea
              description={description}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          ) : (
            <DefaultTextArea
              value={description}
              onChange={e => setDescription(e.target.value)}
              id="description"
              required
            />
          )}
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label>PDF para download</label>
          <label htmlFor="extra-materials" className="extra-materials">
            Selecionar PDF
          </label>
          <ExtraMaterialsInput
            id="extra-materials"
            type="file"
            onChange={e => addTermInPdf(e)}
            ref={inputRef}
          />
          <ExtraMaterialList>
            {Object.keys(extraTerm).length && extraTerm.filename !== '' ? (
              <ExtraMaterial>
                <h5>{extraTerm.name}</h5>
                {extraTerm.reference ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={extraTerm.reference}
                  >
                    <BiDownload size={14} />
                  </a>
                ) : (
                  <></>
                )}

                <button onClick={() => removeTermInPdf()} type="button">
                  <BiTrash size={14} />
                </button>
              </ExtraMaterial>
            ) : (
              <p>Não foi encontrado nenhum termo em PDF.</p>
            )}
          </ExtraMaterialList>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToTerms}>
            Cancelar
          </DefaultButton>

          <DefaultButton
            onClick={e =>
              isEditting
                ? handleUpdateAcceptanceTerm(e)
                : handleCreateAcceptanceTerm(e)
            }
            className="success"
          >
            {isEditting ? 'Salvar' : 'Criar'}
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default CreateAndEditTerm;
