import httpClient from "../http-client";

const getAllDepositions = async (filsters: any) => {
  const depositions = (
    await httpClient.get<any[]>(`trails/review/trail` , { params: filsters })
  ).data;

  return depositions;
};

const changeTrailDeposition = async (trailId: string, userId: string) => {
  await httpClient.patch(
    `/trail-user-reviews/${trailId}/${userId}/show-to-all-users`
  );
};

const changeContentDeposition = async (contentId: string, userId: string) => {
  await httpClient.patch(
    `/content-user-reviews/${contentId}/${userId}/show-to-all-users`
  );
};

export { getAllDepositions, changeTrailDeposition, changeContentDeposition };
