const checkEmptyHtml = (str: string) => {
  const re = /<h2><\/h2>/i;
  
  if (!str || re.test(str)) {
    return true;
  }

  return false;
}

export default checkEmptyHtml;
