import httpClient from '../http-client';
import {
  UploadedFile,
  UrlSignedResponse,
} from '../models/from-api-response/file';

const uploadFile = async (formData: FormData): Promise<UploadedFile> => {
  const uploadFileResponse = (
    await httpClient.post(`files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;

  return uploadFileResponse;
};

const getSignedUrl = async (contentType: string, path: string) => {
  return (
    await httpClient.post<UrlSignedResponse>('files/url-signed', {
      content_type: contentType,
      path: `scorm/dorconsultoria/${path}`,
    })
  ).data;
};

export { uploadFile, getSignedUrl };
