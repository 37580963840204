import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import { ArticlesContainer } from "./style";
import {
  getArticles as getArticlesService,
  aproveArticle as aproveArticleService,
} from "../../services/articles";
import Article from "../../models/article";
import DefaultButton from "../../components/DefaultButton";
import { AiOutlineCheck, AiOutlineClose, AiOutlineEye } from "react-icons/ai";
import Swal from "sweetalert2";
import { showModal } from "../../helpers/modal";
import ArticlePreview from "./components/ArticlePreview";
import ArticleFeedback from "./components/ArticleFeedback";

const Articles: React.FC = () => {
  const [articles, setArticles] = useState([] as Article[]);

  const getArticles = useCallback(async () => {
    const localArticles = await getArticlesService();
    if (localArticles && localArticles.length) {
      setArticles(localArticles);
    }
  }, []);

  const aproveArticle = useCallback(
    async (articleId: string) => {
      Swal.fire({
        title: "Confirmação",
        text: "Tem certeza que deseja aprovar este artigo?",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await aproveArticleService(articleId);

            await getArticles();
          } catch (error) {
            Swal.fire({
              title: "Erro",
              text: "Erro ao aprovar o artigo. " + error.message,
            });
          }
        }
      });
    },
    [getArticles]
  );

  const reproveArticle = useCallback(
    (articleId: string) => {
      showModal(
        "Informar Motivo da Reprovação",
        <ArticleFeedback articleId={articleId} onReprove={getArticles} />
      );
    },
    [getArticles]
  );

  const viewArticle = useCallback((article: Article) => {
    showModal(article.title, <ArticlePreview article={article} />);
  }, []);

  const articlesToBeShown = useMemo(() => {
    if (articles && articles.length) {
      return articles.map((article) => ({
        ...article,
        user: article.authorName,
        creationDate: article.createdAt
          ? new Intl.DateTimeFormat("pt-BR", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            }).format(article.createdAt)
          : null,
        status: article.aproved
          ? "Aprovado"
          : article.pending
          ? "Aguardando aprovação"
          : article.reproved
          ? "Reprovado"
          : "",
        actions: (
          <>
            <DefaultButton
              title="Visualizar artigo"
              className="small info"
              onClick={() => viewArticle(article)}
            >
              <AiOutlineEye />
            </DefaultButton>{" "}
            {!article.aproved && (
              <>
                <DefaultButton
                  title="Aprovar artigo"
                  className="small primary"
                  onClick={() => aproveArticle(article.id)}
                >
                  <AiOutlineCheck />
                </DefaultButton>{" "}
              </>
            )}
            {!article.reproved && (
              <DefaultButton
                title="Reprovar artigo"
                className="small danger"
                onClick={() => reproveArticle(article.id)}
              >
                <AiOutlineClose />
              </DefaultButton>
            )}
          </>
        ),
      }));
    }

    return [];
  }, [articles, aproveArticle, reproveArticle, viewArticle]);

  useEffect(() => {
    getArticles();
  }, [getArticles]);
  return (
    <ArticlesContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Dashboard</Link>, <span>Artigos</span>]}
      />

      <DefaultPageTitle>Artigos</DefaultPageTitle>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: "title",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Autor</span>,
            propName: "authorName",
          },
          {
            headerLabel: <span>Data de criação</span>,
            propName: "creationDate",
          },
          {
            headerLabel: <span>Situação</span>,
            propName: "status",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={articlesToBeShown}
        emptyListMessage={"Não foi encontrado nenhum artigo!"}
      />
    </ArticlesContainer>
  );
};

export default Articles;
