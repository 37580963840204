import {
  AiOutlineFileText,
  AiOutlineUser,
  AiOutlineDashboard,
} from 'react-icons/ai';
import { BsCollectionPlay } from 'react-icons/bs';
import { IoLibraryOutline, IoKeySharp } from 'react-icons/io5';
import { BsClipboardData, BsGraphUp } from 'react-icons/bs';
import { GiPencil, GiPencilRuler, GiTeamUpgrade } from 'react-icons/gi';
import { FiMap } from 'react-icons/fi';
import { FaQuestionCircle, FaUserShield, FaCheckSquare } from 'react-icons/fa';
import { RiPagesLine } from 'react-icons/ri';
import { FiShield } from 'react-icons/fi';
import { VscTasklist } from 'react-icons/vsc';
import { AiOutlinePicture, AiOutlineStar } from 'react-icons/ai';

const menus = [
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineDashboard />
        </span>
        <span className="text">Dashboard</span>
      </>
    ),
    path: '/profile',
  },
  {
    label: (
      <>
        <span className="icon">
          <FaUserShield />
        </span>
        <span className="text">Usuários</span>
      </>
    ),
    path: '/users',
  },
  {
    label: (
      <>
        <span className="icon">
          <IoKeySharp />
        </span>
        <span className="text">Chaves de Acesso</span>
      </>
    ),
    path: '/access-key',
  },
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineUser />
        </span>
        <span className="text">Autores</span>
      </>
    ),
    path: '/authors',
  },
  {
    label: (
      <>
        <span className="icon">
          <RiPagesLine />
        </span>
        <span className="text">Depoimentos</span>
      </>
    ),
    path: '/depositions',
  },
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineStar />
        </span>
        <span className="text">Recomendados</span>
      </>
    ),
    path: '/recommended',
  },
  {
    label: (
      <>
        <span className="icon">
          <AiOutlinePicture />
        </span>
        <span className="text">Banner</span>
      </>
    ),
    path: '/Banner',
  },
  {
    label: (
      <>
        <span className="icon">
          <IoLibraryOutline />
        </span>
        <span className="text">Categorias</span>
      </>
    ),
    path: '/categories',
  },
  {
    label: (
      <>
        <span className="icon">
          <BsCollectionPlay />
        </span>
        <span className="text">Trilhas</span>
      </>
    ),
    path: '/trails',
  },
  {
    label: (
      <>
        <span className="icon">
          <FiMap />
        </span>
        <span className="text">Cursos</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiPencil />
            </span>
            <span className="text">Conteúdos</span>
          </>
        ),
        path: '/course/contents',
      },
      {
        label: (
          <>
            <span className="icon">
              <AiOutlineFileText />
            </span>
            <span className="text">Módulos</span>
          </>
        ),
        path: '/course/episodes',
      },
      {
        label: (
          <>
            <span className="icon">
              <IoLibraryOutline />
            </span>
            <span className="text">Cursos</span>
          </>
        ),
        path: '/course/series',
      },
    ],
  },

  {
    label: (
      <>
        <span className="icon">
          <FaQuestionCircle />
        </span>
        <span className="text">FAQs</span>
      </>
    ),
    path: '/faqs',
  },

  {
    label: (
      <>
        <span className="icon">
          <FiShield />
        </span>
        <span className="text">Segmentação</span>
      </>
    ),
    path: '/segmentation',
  },
  {
    label: (
      <>
        <span className="icon">
          <GiPencilRuler />
        </span>
        <span className="text">Provas e Questões</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiPencil />
            </span>
            <span className="text">Provas</span>
          </>
        ),
        path: '/exams-questions/exams',
      },
      {
        label: (
          <>
            <span className="icon">
              <AiOutlineFileText />
            </span>
            <span className="text">Questões</span>
          </>
        ),
        path: '/exams-questions/questions',
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <BsClipboardData />
        </span>
        <span className="text">Relatórios</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiTeamUpgrade />
            </span>
            <span className="text">Engajamento</span>
          </>
        ),
        path: '/reports/engagement',
      },
      {
        label: (
          <>
            <span className="icon">
              <VscTasklist />
            </span>
            <span className="text">Cursos Finalizados</span>
          </>
        ),
        path: '/reports/finishedCourses',
      },
      {
        label: (
          <>
            <span className="icon">
              <FaCheckSquare />
            </span>
            <span className="text">Termos de Compliance</span>
          </>
        ),
        path: '/reports/terms',
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <FaCheckSquare />
        </span>
        <span className="text">Termos</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <FaCheckSquare />
            </span>
            <span className="text">Termos de Compliance</span>
          </>
        ),
        path: '/terms',
      },
      {
        label: (
          <>
            <span className="icon">
              <FaCheckSquare />
            </span>
            <span className="text">Termo de Compromisso</span>
          </>
        ),
        path: '/responsibility-term',
      },
      {
        label: (
          <>
            <span className="icon">
              <FaCheckSquare />
            </span>
            <span className="text">Termo de Privacidade</span>
          </>
        ),
        path: '/privacy-term',
      },
    ],
  },
];

const menus2 = [
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineDashboard />
        </span>
        <span className="text">Dashboard</span>
      </>
    ),
    path: '/profile',
  },
  {
    label: (
      <>
        <span className="icon">
          <FiMap />
        </span>
        <span className="text">Cursos</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiPencil />
            </span>
            <span className="text">Conteúdos</span>
          </>
        ),
        path: '/course/contents',
      },
      {
        label: (
          <>
            <span className="icon">
              <AiOutlineFileText />
            </span>
            <span className="text">Módulos</span>
          </>
        ),
        path: '/course/episodes',
      },
      {
        label: (
          <>
            <span className="icon">
              <IoLibraryOutline />
            </span>
            <span className="text">Cursos</span>
          </>
        ),
        path: '/course/series',
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <GiPencilRuler />
        </span>
        <span className="text">Provas e Questões</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiPencil />
            </span>
            <span className="text">Provas</span>
          </>
        ),
        path: '/exams-questions/exams',
      },
      {
        label: (
          <>
            <span className="icon">
              <AiOutlineFileText />
            </span>
            <span className="text">Questões</span>
          </>
        ),
        path: '/exams-questions/questions',
      },
    ],
  },
];

export { menus, menus2 };
