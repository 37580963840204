import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import {
  activateOrInactivateCategory as activateOrInactivateCategoryService,
  getAllCategories,
  deleteCategory,
} from '../../services/categories';
import { CategoriesContainer } from './style';
import Category from '../../models/category';
import DefaultInput from '../../components/DefaultInput';
import { DefaultCreateButtonAndSearchFormContainer } from '../../components/DefaultCreateButtonAndSearchFormContainer';

const Categories: React.FC = () => {
  const history = useHistory();

  const [categories, setCategories] = useState([] as Category[]);
  const [categoryName, setCategoryName] = useState('');

  const getCategories = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const localCategories = await getAllCategories({ title: categoryName });
    setCategories(localCategories || []);
  };

  const editCategory = useCallback(
    (categoryId: any) => {
      history.push(`edit-category/${categoryId}`);
    },
    [history],
  );

  const removeCategory = useCallback(async (categoryId: any) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta categoria?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteCategory(categoryId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Categoria excluida com sucesso!',
          });

          await getCategories();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir categoria. ${
              (error.response && error.response.status === 423) || 400
                ? 'Esta categoria já está associada a um curso!'
                : error.message
            }`,
          });
        }
      }
    });
  }, []);

  const createCategory = async () => {
    history.push('create-category');
  };

  const activateOrInactivateCategory = useCallback(
    async (categoryId: any, activate: boolean) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${
          activate ? 'ativar' : 'inativar'
        } esta categoria?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCategoryService(categoryId, activate);

            getCategories();
          } catch (error) {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao ${activate ? 'ativar' : 'inativar'} categoria. ${
                (error.response && error.response.status === 423) || 400
                  ? 'Esta categoria já está associada a um curso!'
                  : error.message
              }`,
            });
          }
        }
      });
    },
    [],
  );

  const contentsToBeShown = useMemo(() => {
    return categories && categories.length
      ? categories.map(category => ({
          id: category.category_id,
          title: category.title,
          description: category.description,
          active: category.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <DefaultButton
                onClick={() => editCategory(category.category_id)}
                className="small info"
                title="Editar Categoria"
              >
                <BiEdit />
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() =>
                  activateOrInactivateCategory(
                    category?.category_id,
                    !category.is_active,
                  )
                }
                className="small warning"
                title={
                  (category.is_active ? 'Inativar' : 'Ativar') + ' Categoria'
                }
              >
                {category.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{' '}
              <DefaultButton
                onClick={() => {
                  removeCategory(category.category_id);
                }}
                className="small danger"
                title="Excluir Categoria"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [categories, editCategory, removeCategory, activateOrInactivateCategory]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (!categoryName) {
      getCategories();
    }
  }, [categoryName]);

  return (
    <CategoriesContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Dashboard</Link>, <span>Categorias</span>]}
      />

      <DefaultPageTitle>Categorias</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createCategory}>
            Criar Categoria
          </DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={getCategories}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '10px',
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Digite sua pesquisa aqui..."
            value={categoryName}
            onChange={e => setCategoryName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton
            className="small"
            style={{ margin: 0, height: '43px' }}
            type="submit"
          >
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
            attributes: { style: { width: '35%' } },
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
            order: (_: any[], currentOrder: string) => {
              setCategories([
                ...categories.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes('active') &&
                    currentOrder.includes('asc');
                  if (isDescendant) {
                    return a.is_active ? 1 : -1;
                  } else {
                    return a.is_active ? -1 : 1;
                  }
                }),
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados categorias cadastradas!'}
      />
    </CategoriesContainer>
  );
};

export default Categories;
