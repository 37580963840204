import styled from 'styled-components';

const UsersContainer = styled.div``;
const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: var(--font-small);
    color: var(--primary-color);
  }
`;

export { UsersContainer, SearchContainer };
